import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Modal from "./Addons/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { getElements } from '../Actions';
import Paginated from "./Addons/Paginated";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Auth/AuthProvider";
import Loading from "./Addons/Loading";
import DiscordButton from "./Addons/DiscordButton";

export default function Ranking({ setSelectedOption = false }) {

    const { t } = useTranslation();
    const auth = useAuth();

    useEffect(() => {
        auth.checkTokens();
    }, [])

    const lng = navigator.language;

    const location = useLocation();


    const dispatch = useDispatch();
    const statistics = useSelector((state) => state.elements);

    const [currentCategory, setCurrentCategory] = useState("");

    useEffect(() => {
        dispatch(getElements());
        return window.scroll(0, 0);
    }, [dispatch]);

    useEffect(() => {
        if (!setSelectedOption) {
            if (location.state) {
                setCurrentCategory(location.state);
            } else {
                let category = (window.location.href).split("/")[4];
                setCurrentCategory(category[0].toUpperCase() + category.slice(1));
            }
        } else {
            setCurrentCategory('Players')
        }
    }, [])


    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });

    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (isModal.active || loading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModal, loading])




    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

    }


    const [currentPage, setCurrentPage] = useState(1);
    const [elemPerPage, setElemPerPage] = useState(10);
    const [orderElem, setOrderElem] = useState(false);
    const indexOfLastCharacter = currentPage * elemPerPage;
    const indexOfFirstCharacter = indexOfLastCharacter - elemPerPage;
    const currentElem = (statistics?.[`top${currentCategory}Table`])?.slice(indexOfFirstCharacter, indexOfLastCharacter);


    const paginado = (pages) => {
        setCurrentPage(pages)
    }


    function secondsToHoursAndMinutes(seconds) {
        if (seconds < 0) {
            throw new Error("Seconds must be a non-negative number.");
        }

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        if (hours == 0) {
            return `${minutes}min`;

        } else {
            return `${hours}h ${minutes}min`;
        }
    }

    function getMinutesDifference(targetDate) {
        const targetDateObj = new Date(targetDate);

        const now = new Date();

        if (isNaN(targetDateObj.getTime())) {
            throw new Error("La fecha objetivo no es válida.");
        }

        const differenceMs = Math.abs(now - targetDateObj);

        const differenceMinutes = Math.floor(differenceMs / (1000 * 60));

        return differenceMinutes;
    }



    window.addEventListener("scroll", changeNavbar);


    return (
        statistics?.playersOnline ?
            <div style={{ height: setSelectedOption ? 'auto' : "100svh" }} className="body__content" id="body__content">

                {!setSelectedOption && <Navbar setIsModal={setIsModal} navbar={navbar} setCurrentCategory={setCurrentCategory} setCurrentPage={setCurrentPage} />}

                <section style={{margin:'auto'}} className={!setSelectedOption ? "rank-table-container" : "account-row__user-info"}>
                    <div style={{ display: 'flex', position: 'relative' }} className="section-title">
                        {currentCategory == 'Players' && setSelectedOption && <h2 style={{ minWidth: '50%' }}>{t('translations:best_players')}</h2>}
                        {currentCategory == 'Guilds' && setSelectedOption && <h2 style={{ minWidth: '50%' }}>{t('translations:best_guilds')}</h2>}
                        {setSelectedOption && <div style={{ marginLeft: '20%', display: 'flex' }}>
                            <div style={{ fontSize: '14px', fontFamily: 'Open sans', fontWeight: '400', textTransform: 'none', margin: '0 15px', display: 'flex', cursor: 'pointer' }}>
                                <img src={require("../img/props/dot-table.png")} style={{marginRight:'2px'}} className="account-user-coins__image" alt="Homepage" />
                                <span style={{color: currentCategory !== 'Players' ? '#FDACAC' : 'white'}} onClick={() => { setCurrentCategory("Players") }} >Players</span>
                            </div>
                            <div style={{ fontSize: '14px', fontFamily: 'Open sans', fontWeight: '400', textTransform: 'none', margin: '0px 15px', display: 'flex', cursor: 'pointer' }}>
                                <img src={require("../img/props/dot-table.png")} style={{marginRight:'2px'}} className="account-user-coins__image" alt="Homepage" />
                                <span style={{color: currentCategory !== 'Guilds' ? '#FDACAC' : 'white'}} onClick={() => { setCurrentCategory("Guilds") }} >Guilds</span>
                            </div>

                        </div>}
                    </div>
                    {!setSelectedOption && <div style={{ maxWidth: '51rem' }} className="section-title">
                        <img src={require("../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                        <h2>
                            {currentCategory == 'Players' && t('translations:best_players')}
                            {currentCategory == 'Guilds' && t('translations:best_guilds')}
                            {currentCategory == 'Votes' && t('translations:top_votes')}
                            {currentCategory == 'Online' && t('translations:best_online')}
                        </h2>
                        <img src={require("../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                    </div>}

                    <table className="table-vote-container__table table-vote-container__table--hover" style={currentElem?.length < 10 ? { marginBottom: (30 * (10 - currentElem.length) + "px") } : {}}>
                        <thead>
                            {currentCategory === 'Players' && (
                                <tr>
                                    <th>#</th>
                                    <th>{t('translations:nickname')}</th>
                                    <th>{t('translations:guild')}</th>
                                    <th>{t('translations:kingdom')}</th>
                                    <th>{t('translations:level')}</th>
                                </tr>
                            )}

                            {currentCategory === 'Guilds' && (
                                <tr>
                                    <th>#</th>
                                    <th>{t('translations:guildname')}</th>
                                    <th>{t('translations:win_losses')}</th>
                                    <th>{t('translations:kingdom')}</th>
                                    <th>{t('translations:level')}</th>
                                </tr>
                            )}

                            {currentCategory === 'Votes' && (
                                <tr>
                                    <th>#</th>
                                    <th>{t('translations:name')}</th>
                                    <th>{t('translations:points')}</th>
                                </tr>
                            )}

                            {currentCategory === 'Online' && (
                                <tr>
                                    <th>#</th>
                                    <th>{t('translations:name')}</th>
                                    <th>{t('translations:time_online')}</th>
                                    <th>{t('translations:status')}</th>
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {currentCategory === 'Players' && currentElem?.map((e, i) => (
                                <tr key={`tr${i}`}>
                                    <td>
                                        {(((currentPage - 1) * 10) + i + 1) < 10 ? '0' +(((currentPage - 1) * 10) + i + 1) : ((currentPage - 1) * 10) + i + 1}
                                    </td>
                                    <td>
                                        <span className="rank-container__table-text">
                                            {e?.job <= 8 && <img src={require(`../img/avatar/${e.job}.png`)} className="rank-container__table-character" alt={t('translations:avatar_alt')} />}
                                            <span>
                                                {e.name}
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        {e.guild_name || t('translations:n_a')}
                                    </td>
                                    <td>
                                        {e?.empire && <img src={require(`../img/empire/${e.empire}.png`)} className="tables-container__table-flag" alt={t('translations:empire_flag_alt')} title={t('translations:empire_flag_title')} />}
                                    </td>
                                    <td className="rank-table__max-color">
                                        {e.level}
                                    </td>
                                </tr>
                            ))}

                            {currentCategory === 'Guilds' && currentElem?.map((e, i) => (
                                <tr key={`tr${i}`}>
                                    <td>
                                    {(((currentPage - 1) * 10) + i + 1) < 10 ? '0' +(((currentPage - 1) * 10) + i + 1) : ((currentPage - 1) * 10) + i + 1}
                                    </td>
                                    <td>
                                        <span style={{display:'flex', justifyContent:'center', justifyItems:'center', textAlign:'center'}} className="rank-container__table-text">
                                            <span>
                                                {e.name}
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        {e.win}/{e.loss}
                                    </td>
                                    <td>
                                        {e?.empire && <img src={require(`../img/empire/${e.empire}.png`)} className="tables-container__table-flag" alt={t('translations:empire_flag_alt')} title={t('translations:empire_flag_title')} />}
                                    </td>
                                    <td className="rank-table__max-color">
                                        {e.level}
                                    </td>
                                </tr>
                            ))}

                            {currentCategory === 'Votes' && currentElem?.map((e, i) => (
                                <tr key={`tr${i}`}>
                                    <td>
                                    {(((currentPage - 1) * 10) + i + 1) < 10 ? '0' +(((currentPage - 1) * 10) + i + 1) : ((currentPage - 1) * 10) + i + 1}
                                    </td>
                                    <td>
                                        <span style={{ color: '#eef3ff !important' }}>
                                            {e.login}
                                        </span>
                                    </td>
                                    <td className="rank-table__max-color">
                                        {e.coins_earned}
                                    </td>
                                </tr>
                            ))}

                            {currentCategory === 'Online' && currentElem?.map((e, i) => (
                                <tr key={`tr${i}`}>
                                    <td>
                                    {(((currentPage - 1) * 10) + i + 1) < 10 ? '0' +(((currentPage - 1) * 10) + i + 1) : ((currentPage - 1) * 10) + i + 1}
                                    </td>
                                    <td>
                                        <span className="rank-container__table-text">
                                            <img src={require(`../img/avatar/${e.job}.png`)} className="rank-container__table-character" alt={t('translations:avatar_alt')} />
                                            <span>
                                                {e.name}
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        {secondsToHoursAndMinutes(e.playtime)}
                                    </td>
                                    <td>
                                        {getMinutesDifference(e.last_play) <= 10 ? <span className="tables-container__status tables-container__status--online"></span> : <span className="tables-container__status tables-container__status--offline"></span>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="pagination__column-container">
                        <div className="controls-type-basic__pagination unselectable">
                            <Paginated elemPerPage={elemPerPage} statistics={statistics?.[`top${currentCategory}Table`]?.length} paginado={paginado} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                        </div>
                        <div className="pagination__count">
                            {t('translations:showing_range', { start: indexOfFirstCharacter + 1, end: ((currentPage - 1) * 10) + currentElem?.length, total: statistics?.[`top${currentCategory}Table`]?.length })}
                        </div>
                    </div>
                </section>



                { loading && <Loading request={true} /> }
                
                {!setSelectedOption && <DiscordButton />}

                {!setSelectedOption && <Footer />}
                {!setSelectedOption && <Modal isModal={isModal} setIsModal={setIsModal} setLoading={setLoading} />}
            </div>
            : <Loading />

    )
}