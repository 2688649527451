import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL, PAGE_DOMAIN } from "../../Auth/authConstants2";
import axios from "axios";
import { useAuth } from "../../Auth/AuthProvider";


export default function MassMails({message, setMessage}) {

    const { t } = useTranslation();

    const auth = useAuth();


    const [mailsInputs, setMailsInputs] = useState({
        mail: '',
        title: '',
        content: ''
    })

    const [updateStates, setUpdateStates] = useState(false);
    const [mailType, setMailType] = useState('test');


    const [pContainer, setPContainer] = useState({ ['0']: '' });




    const mail = `<html>
            <head>
                <style>
                    .body-mail {
                        font-family: Arial, sans-serif !important;
                        color: #fff !important;
                        margin: 0 !important;
                        padding: 0 !important;
                        background: rgba(41, 2, 2, 1) !important;
                    }
                    .container-mail {
                        width: 100% !important;
                        max-width: 600px !important;
                        margin: 0 auto !important;
                        background: #380505 !important;
                        padding: 20px !important;
                        color: #fff !important;
                        border-radius: 8px !important;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
                    }
                    .logo-mail {
                        text-align: center !important;
                        margin-bottom: 20px !important;    
                        justify-content: center;
    display: flex;
                    }
                    .footer-logo-mail {                        
                        margin-right: 20px !important;
                    }
                    .footer-logo-mail img{
                        max-width: 150px !important;
                        height: auto !important;
                    }
                    .logo-mail img {
                        max-width: 150px !important;
                        height: auto !important;
                    }
                    .content-mail {
                        text-align: center !important;
                    }
                    .button-mail {
                        display: inline-block !important;
                        width: 350px !important;
			            height: 70px !important;
                        font-size: 16px !important;
			            background-image: url('${PAGE_DOMAIN}/event.png') !important;
    			        background-position: center !important;
    			        background-size: 100% 100% !important;
    			        background-repeat: no-repeat !important;
                        text-decoration: none !important;
			            font-weight: bold !important;
                        border-radius: 5px !important;
                        margin-top: 20px !important;
                        color: #FFF !important;
			            display: flex !important;
			            align-items: center !important;
			            justify-content: center !important;
			            margin: auto !important;
                    }   
                    .button-mail span {
                        text-decoration: none !important;
                        color: #FFF !important;
                        margin:auto !important;
                    }
                    .footer-mail {
                        text-align: center !important;
                        font-size: 12px !important;
                        margin-top: 20px !important;                        
                    }
                    .div-footer-container-mail {
                        display: flex !important;
                        justify-content: center !important;       
                    }
		            .footer-container-mail{
			            display: flex !important;
                        margin: auto !important;
                        justify-content: center !important;
		            }
                    .footer-right-mail {
                        margin-left: 20px !important;
                        padding-top: 20px !important;
                    }
		            .span-bold-mail {
			            font-weight: bold !important;
                        display:block !important;
                        color: black !important;
		            }
                    .a-flex-mail {
                        display: flex !important;
                    }
                    .a-mail img{
                        width: 30px !important;
                    }
                    .a-mail span {
                    margin-top: 5px !important;
                    margin-left: 5px !important;
                    }
                    .links-container-mail{
                        margin: auto !important;
                        margin-top: 10px !important;
                        display: flex !important;
                        justify-content: center !important;
                    }
                    .links-container-mail div{
                        margin-left: 40px !important;
                        margin-top: 10px !important;
                    }

                    h3 {
                    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;}
                </style>
            </head>
            <body class='body-mail'>
                <div style="" class="container-mail">
                    <div class="logo-mail">
                        <img src="${PAGE_DOMAIN}/logo.gif" alt="Your Logo">
                    </div>
                    <div class="content-mail">
                        <h2>${mailsInputs.title}</h2>
                        ${(Object.values(pContainer)).map((e,i) => `<p>${e}</p>`).join('')
                        }
                    </div>
                </div>
                <div class='footer-mail'>
                <h3>${t('translations:visit_us')}</h3>
                <h3>${t('translations:received_this_mail')}</h3>
                <div class='div-footer-container-mail'>
                <div class="footer-container-mail">
                    <div class="footer-logo-mail">
                        <img src="${PAGE_DOMAIN}/logo.gif" alt="Your Logo">
                    </div>
		    <div class="footer-right-mail">
			<span  class="span-bold-mail">${t('translations:contact_us')}</span>
			<a class="a-mail" href='https://discord.gg/cypher2'><span>Discord</span></a>
			<a class="a-mail" href='https://www.facebook.com/share/g/6uB81BdmQzsk2DSj/'><span>Facebook</span></a>
            <span class="span-bold-mail">&copy; Cypher2 ${new Date().getFullYear()}. ${t('translations:all_rights')}</span>
            <div class="links-container-mail">
            <div>
            <a class='a-mail a-flex-mail' href='${PAGE_DOMAIN}'><img src='${PAGE_DOMAIN}/discord.png'></img><span class="span-bold">${t('translations:follow_discord')}</span></a>
            <a class='a-mail a-flex-mail' href='${PAGE_DOMAIN}'><img src='${PAGE_DOMAIN}/facebook.png'></img><span class="span-bold">${t('translations:follow_facebook')}</span></a>
            <a class='a-mail a-flex-mail' href='${PAGE_DOMAIN}'><img src='${PAGE_DOMAIN}/tiktok.png'></img><span class="span-bold">${t('translations:follow_tiktok')}</span></a>
            <a class='a-mail a-flex-mail' href='${PAGE_DOMAIN}'><img src='${PAGE_DOMAIN}/youtube.png'></img><span class="span-bold">${t('translations:follow_youtube')}</span></a>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </body>
            </html>`


            const handleDelete = (i) => {

                if ((Object.keys(pContainer)).length > 1) {
                    let pContainerFiltered = pContainer;
                    delete pContainerFiltered[i];

                    setPContainer(pContainerFiltered);
                    setUpdateStates(!updateStates);
                }
            }


            const handleAdd = () => {

                const lastElem = (Object.keys(pContainer))[(Object.keys(pContainer)).length - 1];
                if (lastElem == Object.keys(pContainer).length - 1) {
                    setPContainer({ ...pContainer, [Object.keys(pContainer).length]: '' })

                } else {
                    setPContainer({ ...pContainer, [parseInt(lastElem) + 1]: '' })
                }


            }


            const handleSubmit = async (e) =>  {
                e.preventDefault();
            
            
                try {
                  await axios.post(`${API_URL}/send-mail`, {mail: mailsInputs.mail, mailType, title: mailsInputs.title, content: pContainer }, {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${auth.getAccessToken()}`
                    }
                  });
                  setMessage({ type: 'success', message: 'mail_sent_success' });
                } catch (error) {
                  console.error('Error al enviar mail:', error);
                  setMessage({ type: 'error', message: 'send_mail_error' });
                }

            }





    return (
        <div className="account-row-2">
            <div className="section-title">
                <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
                <h2>{t('translations:page_settings')}</h2>
                <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
            </div>

            <span className="line">
            </span>


            <form className="account-row__user-info">
                <input type="hidden" name="_token" value="iKf7WpIL2qicOGt2sZWSKFoSMPLseElKWvjQ7zXK" autoComplete="off" />
                <div className="form-container__item-container" data-characters="30">
            <select onChange={(e) => {setMailType(e.target.value)}} style={{marginTop:'15px', background: '#160202',color: '#783939', border: '0.063rem solid #783939', padding: '5px 20px 5px 5px', fontSize: '16px'}}>
              <option disabled>{t('translations:mail_type')}</option>
              <option selected value={'test'}>{t('translations:test_mail')}</option>
              <option value={'mass'}>{t('translations:mass_mail')}</option>
            </select>
                    {mailType == 'test' && <label className="form-container__item-title" htmlFor="A1MMDYSPUH">
                        {t('translations:test_mail')}
                    </label>}

                    {mailType == 'test' && <input
                        type="text"
                        name="mail"
                        onChange={(e) => setMailsInputs({ ...mailsInputs, [e.target.name]: e.target.value })}
                        value={mailsInputs.mail}
                        className="form-container__item-input"
                        id="A1MMDYSPUH"
                    />}
                    <label style={{marginTop:'10px'}} className="form-container__item-title" htmlFor="A1MMDYSPUH">
                        {t('translations:title')}
                    </label>

                    <input
                        type="text"
                        name="title"
                        onChange={(e) => setMailsInputs({ ...mailsInputs, [e.target.name]: e.target.value })}
                        value={mailsInputs.title}
                        className="form-container__item-input"
                        id="A1MMDYSPUH"
                    />

                    <div className="form-container__item-bag">
                        <p className="form-container__item-error">
                        </p>
                        <span className="form-container__item-counter">
                            30
                        </span>
                    </div>
                </div>



                {
                    Object.keys(pContainer).map((e, i) => {
                        return (
                            <div key={i + 'mails'} style={{ marginTop: '10px' }} className="form-container__item-container" data-characters="250">
                                <label className="form-container__item-title" htmlFor="54ZUDUL3D8">
                                    {t('translations:paragraph', { number: i + 1 })}
                                </label>
                                <textarea

                                    type="text"
                                    name="content"
                                    style={{ minHeight: '100px' }}
                                    onChange={(e) => setPContainer({ ...pContainer, [i]: e.target.value })}
                                    value={pContainer[i]}
                                    className="form-container__item-input"
                                    id="54ZUDUL3D8"
                                />
                                <button onClick={() => handleDelete(i)} style={{ margin: '20px 5px' }} type="button" className="noDefaultStyle account-form__button">
                                    {t('translations:delete_paragraph')}
                                </button>

                            </div>
                        )
                    })
                }


                <button onClick={() => handleAdd()} style={{ margin: '0px 5px' }} type="button" className="noDefaultStyle account-form__button">
                    {t('translations:add_paragraph')}
                </button>
                <button onClick={(e) => handleSubmit(e)} style={{ margin: '20px 5px' }} type="button" className="noDefaultStyle account-form__button">
                    {t('translations:send_mail')}
                </button>

                <div className="form-container__item-bag">
                    <p className="form-container__item-error">
                    </p>
                    <span className="form-container__item-counter">
                    </span>
                </div>
            </form >

            <div style={{background: 'white', paddingTop: '20px', marginTop:'30px', minWidth: '900px', borderRadius: '10px'}} dangerouslySetInnerHTML={{__html: mail}}>                
            </div>
        </div >
    )
}