import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../Auth/authConstants2";


export default function DiscordButton() {


    const [discordUsers, setDiscordUsers] = useState(0);

    useEffect(() => {
        axios.get(`${API_URL}/get-discord-users`).then((response) => {
            if (!response.data.error) {
                setDiscordUsers(response.data.response);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    return (<a id="discord" href="https://discord.gg/cypher2" target="_blank">
        <div style={{ position: 'absolute', top: '10px', left: '5px', display: 'flex' }}>

            <img src={require("../../img/social/logo.gif")} style={{ width: '70px' }} />
            <div style={{ marginLeft: '5px' }}>
                <span style={{ fontSize: '15px', color: 'white', fontWeight: 'bold', display: 'block', marginBottom: '-5px', marginTop: '5px' }}>Cypher2</span>
                <span style={{ fontSize: '14px', color: 'white', marginTop: '-5px' }}>{discordUsers} Online</span>
            </div>

        </div>
        <img src={require('../../img/props/widget3.png')} alt="Join Server" />
    </a>)
}