import React, { useEffect, useState } from 'react';
import Odometer from 'react-odometerjs';

export default function Counter ({online}) {
    const [value, setValue] = useState(online);
/*
    useEffect(() => {
        const timeoutId = setTimeout(() => setValue(online+5), 2000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);*/




    function addRandomNumber(baseNumber) {
        // Genera un número aleatorio entre 1 y 6
        let randomNumber = Math.floor(Math.random() * 6) + 1;
        // Suma el número aleatorio al número base

        return baseNumber + randomNumber;
    }

    function handleCounter(baseNumber) {
        const interval = setInterval(() => {
            let result = addRandomNumber(baseNumber);
            setValue(result)
        }, 3000);
        return () => clearInterval(interval);

    }




    useEffect(() => {
        return handleCounter(online)
    }, [])


    return <Odometer value={value} format="(dddd)" />;
}