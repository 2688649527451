import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import '../../Styles/components/news.css'
import { API_URL } from "../../Auth/authConstants2";


export default function NewsCard({newData}) {

    const { t } = useTranslation();
    return (
        <div style={{ backgroundImage: `url(${require('../../img/background/table.png')}`, maxWidth: '280px', padding: '0' }} class=" table-container__table-container">
            <a className="w-[100%]" href="#">
                <img class="rounded-t-lg " style={{width: '100%', height: '180px'}} src={`${API_URL + newData.imgURL}`} alt="" />
            </a>
            <div class="pt-3 pb-8 px-5 relative">
                <a href="#">
                    <h3 class="dark:text-white">{newData.title}</h3>
                </a>
                <p style={{
                    color: '#fdacac', fontSize: '14px', height: '100px',
                    width: '250px',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 5,
                    WebkitBoxOrient: 'vertical',
                    lineHeight: '1.4em',
                    textOverflow: 'ellipsis',
                }} class="">{newData.content}</p>

                <div style={{ bottom: '-20px', left: '50%', transform: 'translate(-50%)' }} className="absolute ">
                    <Link to={`/news/article?id=${(newData.imgAlt).split('-')[1]}`} className="noDefaultStyle table-container__show-more">
                        <span className="noDefaultStyle news-container__control-arrow"></span>{t('translations:show_full_new')}
                    </Link>
                </div>
            </div>
        </div>
    )
}