import React from 'react';

export default function Paginated({elemPerPage, statistics, paginado, setCurrentPage, currentPage}){
    let pages = [];

    for(let i = 1 ; i <= Math.ceil(statistics/elemPerPage) ; i++ ){
        pages.push(i);
    }
    return (
        <div className="pagination__column-container">
            <ul className="controls-type-basic__pagination unselectable">
                
                <li  style={{transform: 'rotate(45deg)', backgroundColor:'#911616', marginTop: '4px', height: '24px', width: '23px', marginRight: '-12px', zIndex: '150'}} onClick={() => {currentPage > 1 ? setCurrentPage(currentPage-1) : setCurrentPage(currentPage)}}>
                <div style={{transform:'rotate(-45deg)', marginTop:'3px'}} className="noDefaultStyle news-container__control-arrow" ></div>   
                </li>
                {
                    pages && pages.map(p => {
                        return(<li onClick={() => paginado(p)} style={{cursor:'pointer',width: p == pages.length || p == 1 ? '3rem' : '2rem' , paddingRight: p == pages.length ? '1rem' : 'auto', paddingLeft: p == 1 ? '1rem' : 'auto'}} className="pagination__btn-container pagination__btn-container--current" key={p}>
                            <a style={{fontWeight:'300', color: p !== currentPage ? '#FDACAC' : 'white'}} >{p < 10 ? '0' + p : p}</a>
                        </li>)
                    })
                }                
                <li style={{transform: 'rotate(45deg)', backgroundColor:'#911616', marginTop: '4px', height: '24px', width: '23px', marginLeft: '-12px'}} onClick={() => {currentPage < pages.length ? setCurrentPage(currentPage+1) : setCurrentPage(currentPage)}} >
                    <div style={{transform:'rotate(130deg)', marginTop:'3px'}} className="noDefaultStyle news-container__control-arrow" ></div>                    
                </li>
            </ul>
        </div>
    )
}