import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Styles/main/app.css'
import './Styles/main/menu.css'
import './Styles/main/modal.css'
import './Styles/main/toast_notification.css'
import './Styles/components/account.css'
import './Styles/components/accountForm.css'
import './Styles/components/dashboard.css'
import './Styles/components/download.css'
import './Styles/components/downloadClient.css'
import './Styles/components/footer.css'
import './Styles/components/form.css'
import './Styles/components/recoverAccount.css'
import './Styles/components/staticPage.css'
import './Styles/components/welcomeHeader.css'
import './Styles/components/tableCards.css'
import './Styles/components/rankTable.css'
import './Styles/components/odometer.css'
//import './Styles/components/voteTable.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './Store';
import { AuthProvider } from "./Auth/AuthProvider.jsx";
import './i18n';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AuthProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
