import React, { useEffect, useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useTranslation } from "react-i18next";

export default function Dashboard({ setSelectedOption }) {


    const auth = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        auth.checkTokens();
    }, [setSelectedOption])




    function checkDate(dateString) {
        const inputDate = new Date(dateString);

        const currentDate = new Date();

        if (currentDate > inputDate) {
            return { hasPassed: false };
        } else {
            const day = String(inputDate.getDate()).padStart(2, '0');
            const month = String(inputDate.getMonth() + 1).padStart(2, '0');
            const year = inputDate.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;

            return { hasPassed: true, date: formattedDate };
        }
    }


    const [goldExpire, setGoldExpire] = useState({
        hasPassed: false
    })

    const [silverExpire, setSilverExpire] = useState({
        hasPassed: false
    })



    useEffect(() => {
        let gold = checkDate(auth?.getUser()?.gold_expire);
        let silver = checkDate(auth?.getUser()?.silver_expire);

        setGoldExpire(gold);
        setSilverExpire(silver);

    }, [])


    return (



        <div className="account-row-2">
            <div className="section-title">
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title')} />
                <h2>
                    {t('translations:my_account')}
                </h2>
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title')} />
            </div>

            <div className="account-row__user-info">
                <div className="account-row__user-row">
                    <div className="account-row__user-title">
                        {t('translations:login')}:
                    </div>
                    <div className="account-row__user-description">
                        {auth?.getUser()?.username}
                    </div>
                </div>
                <div className="account-row__user-row">
                    <div className="account-row__user-title">
                        {t('translations:email')}:
                    </div>
                    <div className="account-row__user-description">
                        {auth?.getUser()?.email || t('translations:n_a')}
                    </div>
                </div>
                <div className="account-row__user-row">
                    <div className="account-row__user-title">
                        {t('translations:registration')}:
                    </div>
                    <div className="account-row__user-description">
                        {auth?.getUser()?.create_time ? (auth.getUser()?.create_time.slice(0, 10).split('-').reverse().join('/')) : t('translations:n_a')}
                    </div>
                </div>
                <div className="account-row__user-row">
                    <div className="account-row__user-title">
                        {t('translations:account_status')}:
                    </div>
                    <div className="account-row__user-description">
                        {t('translations:active')}
                    </div>
                </div>
                <div className="account-row__user-row">
                    <div className="account-row__user-title">
                        {t('translations:premium_status')}:
                    </div>
                    <div className="account-row__user-description">
                        {goldExpire.hasPassed && `${t('translations:gold')} (${goldExpire.date})`}
                        {silverExpire.hasPassed && `${t('translations:silver')} (${silverExpire.date})`}
                        {!goldExpire.hasPassed && !silverExpire.hasPassed && t('translations:n_a')}
                    </div>
                </div>
            </div>

            <span className="line">
            </span>

            <div className="card-title">
                <h2>
                    {t('translations:manage_account')}
                </h2>
            </div>

            <div className="account-row__user-manage">
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('changepass')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:change_password')}
                        </h3>
                        <span>
                            {t('translations:change_password_description')}
                        </span>
                    </div>
                </div>
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('changemail')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:change_email')}
                        </h3>
                        <span>
                            {t('translations:change_email_description')}
                        </span>
                    </div>
                </div>
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('changedelet')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:change_delete_code')}
                        </h3>
                        <span>
                            {t('translations:change_delete_code_description')}
                        </span>
                    </div>
                </div>
            </div>

            <span className="line">
            </span>
        </div>

    )
}