import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../Auth/AuthProvider";
import { API_URL } from "../Auth/authConstants2";
import { Link } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Loading from "./Addons/Loading";

export default function Login({ handleModal, setIsModal, setLoading }) {
    const { t } = useTranslation();
    const auth = useAuth();

    const [userData, setUserData] = useState({
        username: '',
        password: ''
    });

    const [errorResponse, setErrorResponse] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        username: '',
        password: ''
    });

    const usernameRegex = /^[a-zA-Z0-9]{5,16}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&/()=@]).{8,16}$/;



    function handleChange(e) {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    }

    function validateForm() {
        const errors = { username: '', password: '' };

        if (!usernameRegex.test(userData.username)) {
            errors.username = t('validation_login_username'); // Use your translation key for the username error
        }

        if (!passwordRegex.test(userData.password)) {
            errors.password = t('validation_login_password'); // Use your translation key for the password error
        }

        setValidationErrors(errors);

        return Object.values(errors).every(x => x === '');
    }


    async function handleLoginSubmit(e) {
        e.preventDefault();

        if (!validateForm()) return;  // Abort if validation fails
        setLoading(true);

        try {
            const response = await fetch(`${API_URL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ username: userData.username, password: userData.password }),
            });

            const json = await response.json();
            if (response.ok) {
                if (json.body.accessToken && json.body.refreshToken) {
                    auth.saveUser(json);
                    setIsModal(false);
                    setUserData({
                        username: '',
                        password: ''
                    });
                    const url = (window.location.href).split('/');
                    if (url[url.length - 1] == 'recover-account') {
                        return window.location.replace('/')
                    }
                }

            } else {
                console.log(response.body)

                setErrorResponse(t('translations:errors:' + json.body.error)); // Generic error message
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                setErrorResponse(t('translations:errors:' + error.body.error)); // Generic error message
            } else {
                setErrorResponse(t('translations:errors:server_error')); // Mensaje de error genérico
            }
        }
        setLoading(false);
    }


    function handleClose() {
        setUserData({
            username: '',
            password: '',
        })
        setValidationErrors({
            username: '',
            password: '',
        })
        setErrorResponse(false);
        handleModal()
    }

    return (
        <form className="user-session__form-container user-session__width" style={{ display: 'flex', position:'relative', backgroundImage: 'url("../../img/background/table2.png") !important' }} id="session-form-login">
            <div style={{ position: 'absolute', right: '10px', top: '8px', zIndex:'1000' }} onClick={() => handleClose()} className="noDefaultStyle modal-container__close-button">
            </div>
            <input type="hidden" name="_token" autoComplete="off" />
            <div className="section-title user-session__width">

                <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%', float:'left' }} src={require("../img/props/title.png")} className="news-container__prop" />
                <div style={{ display: 'flex' }}><span style={{ marginTop: '2px' }} className="noDefaultStyle news-container__control-arrow"></span>{t('translations:login')}</div>

                <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%' }} src={require("../img/props/title2.png")} className="section-title__prop" />
            </div>
                

            <div className="user-session__form-row">
                <div className="user-session__form-input-container">
                    <img src={require("../img/icon/user.png")} className="user-session__form-icon" alt={t('translations:username_icon_alt')} />
                    <input type="text" name="username" value={userData.username} onChange={(e) => handleChange(e)} className="noDefaultStyle user-session__form-input" placeholder={t('translations:username_placeholder')} />
                </div>
                {validationErrors.username && <div style={{ color: 'red', marginTop: '5px', fontSize: '13px' }} className="error-message">{validationErrors.username}</div>}
            </div>

            <div className="user-session__form-row">
                <div className="user-session__form-input-container">
                    <img src={require("../img/icon/padlock.png")} className="user-session__form-icon" alt={t('translations:password_icon_alt')} />
                    <input type="password" name="password" value={userData.password} onChange={(e) => handleChange(e)} className="noDefaultStyle user-session__form-input" placeholder={t('translations:password_placeholder')} />
                </div>
                {validationErrors.password && <div style={{ color: 'red', marginTop: '5px', fontSize: '13px' }} className="error-message">{validationErrors.password}</div>}
            </div>


            <div className="unselectable user-session__helpers-container">
                <div className="noDefaultStyle user-session__helper user-session__helper--white">
                    <input type="checkbox" name="remember_me" className="noDefaultStyle user-session__checkbox" id="remember-me-button" />
                    <label htmlFor="remember-me-button">
                        {t('translations:remember_me')}
                    </label>
                </div>
                <div style={{cursor:'pointer'}} className="noDefaultStyle user-session__helper" onClick={()=> setIsModal({ active: true, type: 'reset' })}>
                    {t('translations:forgot_login_details')}
                </div>
                {/*<Link to="/recover-account" className="noDefaultStyle user-session__helper">
                    {t('translations:forgot_login_details')}
                </Link>*/}
            </div>
            {errorResponse && <div style={{ color: 'red' }} className="error-message">{errorResponse}</div>}

            <button onClick={(e) => handleLoginSubmit(e)} style={{background: 'none'}} type="submit" className="noDefaultStyle user-session__button">
                
                
                <img style={{ filter: 'sepia(1) saturate(6) hue-rotate(-50deg) brightness(0.8) contrast(1.3)', position: 'absolute' }} src={require("../img/props/bttn-copy.png")}></img>
                <span style={{ zIndex: '100' }}>{t('translations:login')}</span>
            </button>
        </form>
    );
}

