import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Modal from "./Addons/Modal";
import { useTranslation } from "react-i18next";
import Loading from "./Addons/Loading";


export default function Terms() {


    const { t } = useTranslation();

    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });


    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
        if (isModal.active || loading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModal, loading])


    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

    }




    window.addEventListener("scroll", changeNavbar);


    return (
        <div >
            <div className="notification-container unselectable" id="notification-container">
            </div>
            <div className="body__content" id="body__content">
                <Navbar setIsModal={setIsModal} navbar={navbar} />


                <div style={{ minHeight: '100vh', height: 'auto', paddingTop: '100px' }} class="reset-form-container">
                    <div style={{ color: '#ffffff', width: '80%', background: 'rgba(55, 1, 1, 0.2)' }} class="reset-form-container__form-container">

                        <h3 style={{ fontSize: '24px' }}>
                            {t('translations:termsAndConditionsTitle')}
                        </h3>
                        <div>
                            <h3>{t('translations:introductionTitleTerms')}</h3>

                            <p>{t('translations:introductionTextTerms')}</p>
                            <br></br>

                            <h3>{t('translations:definitionsTitle')}</h3>
                            <p>
                                - {t('translations:cypher2Definition')}<br></br>
                                - {t('translations:userDefinition')}<br></br>
                                - {t('translations:servicesDefinition')}<br></br>
                            </p>
                            <br></br>

                            <h3>{t('translations:acceptanceOfTermsTitle')}</h3>
                            <p>{t('translations:acceptanceOfTermsText')}</p>
                            <br></br>

<h4>{t('translations:acceptanceOfTermsRefundsTitle')}</h4>
<p>{t('translations:acceptanceOfTermsRefundsText')}</p>
<br></br>

                            <h3>{t('translations:modificationsToTermsTitle')}</h3>
                            <p>{t('translations:modificationsToTermsText')}</p>
                            <br></br>

                            <h3>{t('translations:useOfServicesTitle')}</h3>
                            <br></br>

                            <h4>{t('translations:ageRequirementsTitle')}</h4>
                            <p>{t('translations:ageRequirementsText')}</p>
                            <br></br>

                            <h4>{t('translations:accountRegistrationTitle')}</h4>
                            <p>{t('translations:accountRegistrationText')}</p>
                            <br></br>

                            <h4>{t('translations:userConductTitle')}</h4>
                            <p>{t('translations:userConductText')}</p>
                            <br></br>

                            <h3>{t('translations:intellectualPropertyTitle')}</h3>
                            <p>{t('translations:intellectualPropertyText')}</p>
                            <br></br>

                            <h3>{t('translations:privacyTitle')}</h3>
                            <p>{t('translations:privacyText')}</p>
                            <br></br>

                            <h3>{t('translations:limitationOfLiabilityTitle')}</h3>
                            <p>{t('translations:limitationOfLiabilityText')}</p>
                            <br></br>

                            <h3>{t('translations:indemnificationTitle')}</h3>
                            <p>{t('translations:indemnificationText')}</p>
                            <br></br>

                            <h3>{t('translations:governingLawAndJurisdictionTitle')}</h3>
                            <p>{t('translations:governingLawAndJurisdictionText')}</p>
                            <br></br>

                            <h3>{t('translations:contactTitleTerms')}</h3>
                            <p>{t('translations:contactTextTerms')}</p>
                            <br></br>

                        </div>
                        <div class="static-section-container__space">
                        </div>
                    </div>



                </div>
                {loading && <Loading request={true} />} 
                <Footer />
                <Modal isModal={isModal} setIsModal={setIsModal} setLoading={setLoading} />
            </div>
        </div>

    )
}