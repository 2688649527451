import React, { useEffect, useState } from "react";
import Dashboard from "./Profile/Dashboard";
import ChangePass from "./Profile/ChangePass";
import ChangeMail from "./Profile/ChangeMail";
import ChangeDelet from "./Profile/ChangeDelet";
import { Link } from "react-router-dom";
import PageSettings from "./Profile/PageSettings";
import { useAuth } from "../Auth/AuthProvider";
import { API_URL } from "../Auth/authConstants2";
import SliderSettings from "./PageSettings/SliderSettings";
import NewsSettings from "./PageSettings/NewsSettings";
import CardsSettings from "./PageSettings/CardsSettings";
import Vote from "./Profile/Vote";
import { useTranslation } from "react-i18next";
import AlertModal from "./Addons/AlertModal";
import Ranking from "./Ranking";
import DownloadsSettings from "./PageSettings/DownloadsSettings";
import MassMails from "./PageSettings/MassMail";
import Loading from "./Addons/Loading";




export default function Profile() {

    const auth = useAuth();


    const [selectedOption, setSelectedOption] = useState("dashboard")
    const { t } = useTranslation();
    const [message, setMessage] = useState({
        type: '',
        message: ''
    })

    useEffect(() => {
        auth.checkTokens();
    }, [selectedOption])

    const [loading, setLoading] = useState(false);



    async function handleSignOut(e) {
        e.preventDefault();
        auth.checkTokens();

        try {
            const response = await fetch(`${API_URL}/logout`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.getAccessToken()}`,
                },
            });
            if (response.ok) {
                auth.signout();
            }
        } catch (error) {
            auth.signout();
            console.log(error);
        }
    }



    return (
        <div className="body__content" id="body__content">
            <div className="account">
                <div className="account-row-1">
                    <div className="account-row-content">

                        <h2 className="account-row-title">
                            {t('translations:your_account')}
                        </h2>

                        <div className="account-user-description">
                            <p>
                                {t('translations:hello')}, <span className="account-user-description--bold">{auth.getUser()?.username}</span>
                            </p>

                            <div className="account-user-coins__container">
                                <div className="account-user-coins">
                                    <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Dragon coin" />
                                    <p>{auth.getUser()?.coins}</p>
                                    <span className="account-user-description--bold">{t('translations:dragon_coins')}</span>
                                </div>

                                <div className="account-user-coins">
                                    <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Dragon coin" />
                                    <p>{auth.getUser()?.jcoins}</p>
                                    <span className="account-user-description--bold">{t('translations:j_coins')}</span>
                                </div>

                                <div className="account-user-coins">
                                    <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Dragon coin" />
                                    <p>{auth.getUser()?.cypher_currency}</p>
                                    <span className="account-user-description--bold">{t('translations:cypher_coins')}</span>
                                </div>
                            </div>

                            <div className="account-user-links">
                                <Link to="/" className="noDefaultStyle account-user-links__link">
                                    <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Homepage" />
                                    <p>{t('translations:homepage')}</p>
                                </Link>

                                <div onClick={() => setSelectedOption('dashboard')} style={{ cursor: 'pointer' }} className={selectedOption == 'dashboard' || selectedOption == 'changepass' || selectedOption == 'changemail' || selectedOption == 'changedelet'  ? "noDefaultStyle account-user-links__link account-user-links__link--active" : "noDefaultStyle account-user-links__link"}>
                                    <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="My Account" />
                                    <p>{t('translations:my_account')}</p>
                                </div>
                                <div onClick={() => setSelectedOption('ranking')} style={{ cursor: 'pointer' }} className={selectedOption == 'ranking' ? "noDefaultStyle account-user-links__link account-user-links__link--active" : "noDefaultStyle account-user-links__link"}>
                                    <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Rankings" />
                                    <p>{t('translations:rankings')}</p>
                                </div>

                                <div onClick={() => setSelectedOption('vote')} style={{ cursor: 'pointer' }} className={selectedOption == 'vote'  ? "noDefaultStyle account-user-links__link account-user-links__link--active" : "noDefaultStyle account-user-links__link"}>
                                    <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Vote" />
                                    <p>{t('translations:vote4coins')}</p>
                                </div>

                                {auth.getUser()?.web_admin === 9 && (
                                    <div onClick={() => setSelectedOption('pagesettings')} style={{ cursor: 'pointer' }} className={selectedOption == 'pagesettings' || selectedOption == 'slidersettings' || selectedOption == 'newssettings' || selectedOption == 'cardssettings'  ? "noDefaultStyle account-user-links__link account-user-links__link--active" : "noDefaultStyle account-user-links__link"}>
                                        <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Page settings" />
                                        <p>{t('translations:page_settings')}</p>
                                    </div>
                                )}
                            </div>

                            <div className="account-user-coins">
                                <img src={require("../img/props/dragon-coin.png")} className="account-user-coins__image" alt="Logout" />
                                <form className="noDefaultStyle account-user-description--bold">
                                    <input type="hidden" name="_token" value="vRQmRjIjXj0wvLPXnineb8prWOBl1S9Pth2Xu96k" autoComplete="off" />
                                    <button onClick={(e) => handleSignOut(e)} type="submit" className="noDefaultStyle">
                                        {t('translations:logout')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="account-row-banner">
                        <img src={require("../img/social/logo.gif")} alt="Logo" />
                    </div>
                </div>




                <div style={{position:'relative', width: '100%'}} className="account-row-2">
                {(message.message).length > 0 && <AlertModal message={message} setMessage={setMessage} />}
                    {selectedOption === 'dashboard' && <Dashboard setSelectedOption={setSelectedOption} />}
                    {selectedOption === 'changepass' && <ChangePass setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} setLoading={setLoading} />}
                    {selectedOption === 'changemail' && <ChangeMail setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} setLoading={setLoading} />}
                    {selectedOption === 'changedelet' && <ChangeDelet setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} setLoading={setLoading} />}
                    {selectedOption === 'vote' && <Vote setSelectedOption={setSelectedOption} />}
                    {selectedOption === 'ranking' && <div  style={{width:'100%'}} >
                        <div style={{margin:'auto'}} className="section-title">
                            <img src={require(`../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
                            <h2>{t('ranking')}</h2>
                            <img src={require(`../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
                        </div>
                        <Ranking setSelectedOption={setSelectedOption} /></div>}
                    {selectedOption === 'pagesettings' && auth.getUser()?.web_admin === 9 && <PageSettings setSelectedOption={setSelectedOption} />}
                    {selectedOption === 'slidersettings' && auth.getUser()?.web_admin === 9 && <SliderSettings setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} />}
                    {selectedOption === 'newssettings' && auth.getUser()?.web_admin === 9 && <NewsSettings setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} />}
                    {selectedOption === 'cardssettings' && auth.getUser()?.web_admin === 9 && <CardsSettings setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} />}
                    {selectedOption === 'downloadssettings' && auth.getUser()?.web_admin === 9 && <DownloadsSettings setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} />}
                    {selectedOption === 'mailssettings' && auth.getUser()?.web_admin === 9 && <MassMails setSelectedOption={setSelectedOption} message={message} setMessage={setMessage} />}
                    {loading && <Loading request={true}/>} 
                </div>
            </div>
        </div>

    )
}