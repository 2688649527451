
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/Home';
import Download from './Components/Download';
import Ranking from './Components/Ranking';
import Profile from './Components/Profile';

import { Navigate } from 'react-router-dom';
import ProtectedRoute from './Components/Addons/ProtectedRoute';
import Reset from './Components/Reset';
import Terms from './Components/Terms';
import Policy from './Components/Policy';
import Loading from './Components/Addons/Loading';
import { useTranslation } from 'react-i18next';
import { useEffect, useLayoutEffect } from 'react';
import ResetLink from './Components/ResetLink';
import News from './Components/News';
import NewsContent from './Components/News/NewsContent';


function App() {
  
  const { t, i18n } = useTranslation();
  useEffect(() => {
      const navLang = (navigator.language).slice(0,2);
      const lng = localStorage.getItem('language') || navLang;
      i18n.changeLanguage(lng);
  }, [])

  
  
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};


  return (
    <BrowserRouter>
      <div className="App">
        <Wrapper>
        <Routes>
          {<Route exact path='/' element={<Home />} />}
          {<Route exact path='/download' element={<Download />} />}
          {<Route exact path='/rank/:category' element={<Ranking />} />}
          {<Route exact path='/rank' element={<Navigate to={"/rank/players"} />} />}
          {<Route exact path='/reset-password/:queries' element={<ResetLink />} />}
          {<Route exact path='/terms-of-service' element={<Terms />} />}
          {<Route exact path='/privacy-policy' element={<Policy />} />}
          {<Route exact path='/news' element={<News />} />}
          {<Route exact path='/news/:article' element={<NewsContent />} />}
          {<Route element={<ProtectedRoute isProtected={false} />} >
            <Route path="/profile" element={<Profile />} />
          </Route>}
          {/*<Route path='*' element= {<Error404/>} />*/}
        </Routes>
        </Wrapper>
      </div>
    </BrowserRouter>
  );
}

export default App;
