import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Modal from "./Addons/Modal";
import { useTranslation } from "react-i18next";
import Loading from "./Addons/Loading";


export default function Policy() {


    const { t } = useTranslation();    
    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });


    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
        if (isModal.active || loading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModal, loading])

    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {        
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

    }




    window.addEventListener("scroll", changeNavbar);


    return (
        <div >
            <div className="notification-container unselectable" id="notification-container">
            </div>
            <div className="body__content" id="body__content">
                <Navbar setIsModal={setIsModal} navbar={navbar} />


                <div class="reset-form-container" style={{ minHeight: '100vh', height: 'auto', paddingTop: '100px' }}>

                    <div style={{ color: '#ffffff', width: '80%', background: 'rgba(55, 1, 1, 0.2)', minHeight: '100vh' }} className="reset-form-container__form-container">
                        <h3 style={{ fontSize: '24px' }}>
                            {t('translations:privacyPolicyTitle')}
                        </h3>

                        <div className="static-section-container__space"></div>
                        <br></br>
                        <div>
                            <h3>{t('translations:introductionTitle')}</h3>
                            <p>{t('translations:introductionText')}</p>
                            <br></br>

                            <h3>{t('translations:informationWeCollectTitle')}</h3>
                            <br></br>

                            <h4>{t('translations:informationYouProvideTitle')}</h4>
                            <p>{t('translations:informationYouProvideText')}</p>
                            <br></br>

                            <h4>{t('translations:informationWeCollectAutomaticallyTitle')}</h4>
                            <p>{t('translations:informationWeCollectAutomaticallyText')}</p>
                            <br></br>

                            <h3>{t('translations:useOfInformationTitle')}</h3>
                            <p>{t('translations:useOfInformationText')}</p>
                            <br></br>

                            <h3>{t('translations:sharingInformationTitle')}</h3>
                            <p>{t('translations:sharingInformationText')}</p>
                            <br></br>

                            <h3>{t('translations:informationSecurityTitle')}</h3>
                            <p>{t('translations:informationSecurityText')}</p>
                            <br></br>

                            <h3>{t('translations:dataRetentionTitle')}</h3>
                            <p>{t('translations:dataRetentionText')}</p>
                            <br></br>

                            <h3>{t('translations:userRightsTitle')}</h3>
                            <p>{t('translations:userRightsText')}</p>
                            <br></br>

                            <h3>{t('translations:changesToPrivacyPolicyTitle')}</h3>
                            <p>{t('translations:changesToPrivacyPolicyText')}</p>
                            <br></br>

                            <h3>{t('translations:contactTitle')}</h3>
                            <p>{t('translations:contactText')}</p>
                            <br></br>

                            <div className="static-section-container__space"></div>
                        </div>
                    </div>
                </div>
                {loading && <Loading request={true} />}
                <Footer />
                <Modal isModal={isModal} setIsModal={setIsModal} setLoading={setLoading} />
            </div>
        </div>

    )
}