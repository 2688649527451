import React, { useEffect, useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { API_URL } from "../../Auth/authConstants2";
import { useTranslation } from "react-i18next";
import axios from "axios";
import AlertModal from "../Addons/AlertModal";

export default function ChangeDelet({ setSelectedOption, message, setMessage, setLoading }) {


    const auth = useAuth();

    const [userData, setUserData] = useState({
        deleteCode: '',
        password: '',
    })

    const { t } = useTranslation();

    const [deleteCode, setDeleteCode] = useState('')

    useEffect(()=> {
        try{
        axios.post(`${API_URL}/get-deletecode`, { username: auth.getUser().username } ).then((response)=> {
            setDeleteCode(response.data);
        }
    )}catch(err){
            console.log(err);
        }
    }, [])


    async function handleChangeDelete(e) {
        e.preventDefault();
        setLoading(true);



        try {
            const response = await fetch(`${API_URL}/changedelete`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.getAccessToken()}`,
                },
                body: JSON.stringify({
                    id: auth.getUser()?.id,
                    deleteCode: userData.deleteCode,
                    password: userData.password
                })
            });
            if (response.ok) {
                setUserData({
                    deleteCode: '',
                    password: '',
                });
                setMessage({type: 'success', message:'delete_code_modified'})
            }else {
                setMessage({type: 'error', message:(await response.json()).error})
            }
        } catch (error) {
            console.log(error);
            setMessage({type: 'error', message:error})
        }
        setLoading(false);
    }



     return (
        <div className="account-row-2">
            <div className="section-title">
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_alt')} />
                <h2>
                    {t('translations:my_code')}
                </h2>
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_alt')} />
            </div>

            <div className="account-row__user-info">
                <div className="account-row__user-row">
                    <div className="account-row__user-title">
                        {t('translations:code')}
                    </div>
                    <div className="account-row__user-description">
                        {deleteCode.length == 0 ? t('translations:n_a') : deleteCode}
                    </div>
                </div>
            </div>
            

            <span className="line">
            </span>

            <div className="account-row__user-info">
                <form className="account-form">
                    <input type="hidden" name="_method" value="PUT" />
                    <input type="hidden" name="_token" value="HcCRTrzJXWEqwdppC05kRvE9mOyT3Ta0qpJo7BSP" autoComplete="off" />
                    <div className="account-form__block">
                        <label className="account-form__title">
                            {t('translations:new_code')}
                        </label>
                        <input 
                            type="text" pattern="\d*"
                            maxLength={8}
                            max={8}
                            name="deleteCode" 
                            value={userData.deleteCode} 
                            onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })} 
                            className="noDefaultStyle account-form__input" 
                            placeholder={t('translations:new_code_placeholder')} 
                        />
                    </div>
                    <div className="account-form__block">
                        <label className="account-form__title">
                            {t('translations:password')}
                        </label>
                        <input 
                            type="password" 
                            name="password" 
                            value={userData.password} 
                            onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })} 
                            className="noDefaultStyle account-form__input" 
                            placeholder={t('translations:password_placeholder')} 
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button 
                            onClick={(e) => handleChangeDelete(e)} 
                            style={{ margin: '10px 5px' }} 
                            type="submit" 
                            className="noDefaultStyle account-form__button"
                        >
                            {t('translations:update')}
                        </button>
                        <button 
                            onClick={() => { setSelectedOption('dashboard') }} 
                            style={{ margin: '10px 5px' }} 
                            type="button" 
                            className="noDefaultStyle account-form__button"
                        >
                            {t('translations:back')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}