import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../Auth/AuthProvider";
import { API_URL } from "../Auth/authConstants2";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

export default function Register({ handleModal, setIsModal, setLoading }) {
    const { t } = useTranslation();
    const auth = useAuth();


    const [captchaValue, setCaptchaValue] = useState(null);
    const recaptchaRef = React.createRef();

    const [userData, setUserData] = useState({
        username: '',
        password: '',
        password_verify: '',
        email: '',
    });

    const [errorResponse, setErrorResponse] = useState('');
    const [validationErrors, setValidationErrors] = useState({
        username: '',
        email: '',
        password: '',
        password_verify: '',
        terms: ''
    });

    const [checkboxes, setCheckboxes] = useState({
        terms_of_service: false,
        privacy_policy: false
    });

    // Regex patterns
    const usernameRegex = /^[a-zA-Z0-9]{5,16}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&/()=@]).{8,16}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    function handleChange(e) {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    }

    function handleCheckboxChange(e) {
        const { name, checked } = e.target;
        setCheckboxes({ ...checkboxes, [name]: checked });
    }

    function validateForm() {
        const errors = { username: '', email: '', password: '', password_verify: '', terms: '' };

        if (!usernameRegex.test(userData.username)) {
            errors.username = t('validation_register_username'); // Error message for username
        }

        if (!emailRegex.test(userData.email)) {
            errors.email = t('validation_register_email'); // Error message for email
        }

        if (!passwordRegex.test(userData.password)) {
            errors.password = t('validation_register_password'); // Error message for password
        }

        if (userData.password !== userData.password_verify) {
            errors.password_verify = t('validation_register_password_mismatch'); // Error message for password mismatch
        }

        if (!checkboxes.terms_of_service || !checkboxes.privacy_policy) {
            errors.terms = t('validation_register_terms'); // Error message for checkboxes
        }

        setValidationErrors(errors);
        return Object.values(errors).every(x => x === '');
    }

    async function handleRegisterSubmit(e) {
        e.preventDefault();
        if (!validateForm()) return;  // Abort if validation fails
        setLoading(true);

        if (!captchaValue) {
            return setErrorResponse(t('translations:errors:please_complete_capcha'));
        }

        try {
            const lng = localStorage.getItem('language');
            const response = await fetch(`${API_URL}/register`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    username: userData.username,
                    password: userData.password,
                    email: userData.email,
                    lang: lng,
                    captcha: captchaValue
                }),
            });

            if (response.ok) {
                const json = await response.json();
                console.log(json);

                setUserData({
                    username: '',
                    password: '',
                    password_verify: '',
                    email: ''
                });
                setErrorResponse(t('translations:success:' + json.body.message));
            } else {
                const json = await response.json();
                console.log(json.body.error);
                setErrorResponse(t('translations:errors:' + json.body.error)); // Generic error message
            }
        } catch (error) {
            if (error.response) {
                setErrorResponse(t('translations:errors:' + error.body.message)); // Generic error message
            } else {
                setErrorResponse(t('translations:errors:server_error'));
            }
        }
        setLoading(false);
    }

    function handleClose() {
        setUserData({
            username: '',
            password: '',
            password_verify: '',
            email: ''
        });
        setValidationErrors({
            username: '',
            email: '',
            password: '',
            password_verify: '',
            terms: ''
        });
        setCheckboxes({
            terms_of_service: false,
            privacy_policy: false
        });
        setErrorResponse(false);
        handleModal();
    }


    const onCaptchaChange = (value) => {
        setCaptchaValue(value);
    };


    return (
        <form className="user-session__form-container" style={{ display: 'flex' }} id="session-form-sign-in">
            <div style={{ position: 'absolute', right: '10px', top: '8px', zIndex: '1000' }} onClick={() => handleClose()} className="noDefaultStyle modal-container__close-button"></div>

            <input type="hidden" name="_token" autoComplete="off" />
            <div className="section-title">

                <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%' }} src={require("../img/props/title.png")} className="news-container__prop" />
                <div style={{ display: 'flex' }}><span style={{ marginTop: '2px' }} className="noDefaultStyle news-container__control-arrow"></span>{t('translations:create_account')}</div>

                <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%' }} src={require("../img/props/title2.png")} className="section-title__prop" />
            </div>

            <div className="user-session__form-row">
                <div className="user-session__form-input-container">
                    <img src={require("../img/icon/user.png")} className="user-session__form-icon" alt={t('translations:username_icon_alt')} />
                    <input type="text" name="username" value={userData.username} onChange={(e) => handleChange(e)} className="noDefaultStyle user-session__form-input" placeholder={t('translations:username_placeholder')} />
                </div>
                {validationErrors.username && <div style={{ color: 'red', marginTop: '5px', fontSize: '13px' }} className="error-message">{validationErrors.username}</div>}
            </div>

            <div className="user-session__form-row">
                <div className="user-session__form-input-container">
                    <img src={require("../img/icon/email.png")} className="user-session__form-icon" alt={t('translations:email_icon_alt')} />
                    <input type="text" name="email" value={userData.email} onChange={(e) => handleChange(e)} className="noDefaultStyle user-session__form-input" placeholder={t('translations:email_placeholder')} />
                </div>
                {validationErrors.email && <div style={{ color: 'red', marginTop: '5px', fontSize: '13px' }} className="error-message">{validationErrors.email}</div>}
            </div>

            <div className="user-session__form-row">
                <div className="user-session__form-input-container">
                    <img src={require("../img/icon/padlock.png")} className="user-session__form-icon" alt={t('translations:password_icon_alt')} />
                    <input type="password" name="password" value={userData.password} onChange={(e) => handleChange(e)} className="noDefaultStyle user-session__form-input" placeholder={t('translations:password_placeholder')} />
                </div>
                {validationErrors.password && <div style={{ color: 'red', marginTop: '5px', fontSize: '13px' }} className="error-message">{validationErrors.password}</div>}
            </div>

            <div className="user-session__form-row">
                <div className="user-session__form-input-container">
                    <img src={require("../img/icon/padlock.png")} className="user-session__form-icon" alt={t('translations:password_icon_alt')} />
                    <input type="password" name="password_verify" value={userData.password_verify} onChange={(e) => handleChange(e)} className="noDefaultStyle user-session__form-input" placeholder={t('translations:password_confirmation_placeholder')} />
                </div>
                {validationErrors.password_verify && <div style={{ color: 'red', marginTop: '5px', fontSize: '13px' }} className="error-message">{validationErrors.password_verify}</div>}
            </div>

            <div className="noDefaultStyle user-session__helper user-session__helper--white">
                <div className="g-recaptcha login-container__recaptcha" style={{ margin: "10px auto" }} data-theme="dark" >
                    <ReCAPTCHA
                        sitekey="6Ld1hPgpAAAAAI_J-Tc_zJjURcfdGJ_KUYZsL5OA"
                        onChange={onCaptchaChange}
                        ref={recaptchaRef}
                    /></div>
            </div>

            <div className="unselectable user-session__helpers-container">
                <p>{t('translations:register_agreement')}</p>

                <div className="noDefaultStyle user-session__helper user-session__helper--white">
                    <input type="checkbox" name="terms_of_service" checked={checkboxes.terms_of_service} onChange={(e) => handleCheckboxChange(e)} className="noDefaultStyle user-session__checkbox" />
                    <label htmlFor="terms_of_service">
                        <Link to="/terms-of-service" className="noDefaultStyle user-session__helper">
                            {t('translations:tos')}
                        </Link>
                    </label>
                </div>

                <div className="noDefaultStyle user-session__helper user-session__helper--white">
                    <input style={{ backgroundColor: 'black' }} type="checkbox" name="privacy_policy" checked={checkboxes.privacy_policy} onChange={(e) => handleCheckboxChange(e)} className="noDefaultStyle user-session__checkbox" />
                    <label htmlFor="privacy_policy">
                        <Link to="/privacy-policy" className="noDefaultStyle user-session__helper">
                            {t('translations:privacy_policy')}
                        </Link>
                    </label>
                </div>
                {validationErrors.terms && <div style={{ color: 'red', marginTop: '5px', fontSize: '13px' }} className="error-message">{validationErrors.terms}</div>}
            </div>
            {errorResponse && <div style={{ color: errorResponse.includes('success') ? "green" : "red" }} className="error-message">{errorResponse}</div>}
            <button onClick={(e) => handleRegisterSubmit(e)} style={{ background: 'none' }} type="submit" className="noDefaultStyle user-session__button">

                <img style={{ filter: 'sepia(1) saturate(6) hue-rotate(-50deg) brightness(0.8) contrast(1.3)', position: 'absolute' }} src={require("../img/props/bttn-copy.png")}></img>
                <span style={{ zIndex: '100' }}>{t('translations:register')}</span>
            </button>

        </form>
    );
}





