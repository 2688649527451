import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAuth } from "../../Auth/AuthProvider";
import { API_URL } from "../../Auth/authConstants2";
import { useTranslation } from "react-i18next";

export default function NewsSettings({ setSelectedOption, message, setMessage }) {

    const auth = useAuth();

    const [newsState, setNewsState] = useState('menu');
    const [selectedImage, setSelectedImage] = useState(0);
    const { t, i18n } = useTranslation();

    const [newsInputs, setNewsInputs] = useState({
        title: '',
        content: ''
    })



    const [newsArray, setNewsArray] = useState([]);


    useEffect(() => {
        axios.get(`${API_URL}/get-news`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${auth.getAccessToken()}`
            }
        }).then((response) => {
            setNewsArray(response.data)
            if(newsInputs.title == '' && newsInputs.content == ''){
            setSelectedImage((response.data).length + 1)
        }
        }).catch(err => {
            console.log(err);
        })

    }, [newsState])



    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };


    async function handleSubmit() {
        if (!selectedFile) return setMessage({ type: 'error', message: 'error_select_image' });


        const MAX_SIZE_MB = 2;
        const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

        // Validar tamaño de la imagen
        if (selectedFile.size > MAX_SIZE_BYTES) {
            return setMessage({ type: 'error', message: `files_too_large` });
        }

        let formData = new FormData();
        if (selectedFile !== 'same') {
            formData.append('title', newsInputs.title);
            formData.append('content', newsInputs.content);
            formData.append('image', selectedFile);
        } else {
            formData = {
                title: newsInputs.title,
                content: newsInputs.content,
                image: 'same'
            }
        }

        try {
            await axios.post(`${API_URL}/upload?newImg=${selectedImage}`, formData, {
                headers: {
                    'Content-Type': selectedFile !== 'same' ? 'multipart/form-data' : 'application/json',
                    'Authorization': `Bearer ${auth.getAccessToken()}`
                }
            });

            
            const response = await axios.get(`${API_URL}/get-news`, {
                headers: {
                  "Content-Type": "application/json",
                }
              });
              setNewsArray(response.data);        
              setMessage({type: 'success', message: 'news_upload_success'})
              setNewsInputs({
                  title: '',
                  content: ''
              })

            setSelectedFile(null)
            setNewsState('menu');
        } catch (error) {
            console.error('Error al subir el archivo:', error);
            setMessage({ type: 'error', message: 'file_upload_error' });
        }

    }
    async function handleDelete(e, id) {
        e.preventDefault()

        try {
            let response = await axios.post(`${API_URL}/delete-new`, JSON.stringify({ id }), {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${auth.getAccessToken()}`
                }
            });

            let filteredArray = newsArray.filter((e, i) => i !== id);

            setNewsArray(filteredArray);
            setMessage({ type: 'success', message: response.data })
        } catch (error) {
            console.error('Error al cargar noticia:', error);
        }

    }


    async function handleSet(e, id) {
        e.preventDefault()

        try {
            let response = await axios.post(`${API_URL}/active-new`, JSON.stringify({ id }), {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${auth.getAccessToken()}`
                }
            });

            axios.get(`${API_URL}/get-news`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${auth.getAccessToken()}`
                }
            }).then((response) => {
                setNewsArray(response.data)
            }).catch(err => {
                console.log(err);
            })

            setMessage({ type: 'success', message: response.data })
        } catch (error) {
            console.error('Error al cargar noticia:', error);
        }
    }



    
    function handleModify(event, data, pos) {
        event.preventDefault();


        setNewsState('load');
        setNewsInputs({
            title: data.title,
            content: data.content
        })
        setSelectedFile('same');
        console.log(pos);
        setSelectedImage(pos+1);
    }




    return (

        <div className="account-row-2">
            <div className="section-title">
                <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
                <h2>{t('translations:page_settings')}</h2>
                <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
            </div>

            <span className="line">
            </span>

            {newsState === 'menu' && newsArray?.length > 0 && (
                <div className="account-row__user-info">
                    <div style={{ display: 'block' }} className="news-container">
                        {newsArray?.map((e, i) => (
                            <div key={e + 'array'} className="news-container__row">
                                <div className="news-container__text">
                                    {e.title}
                                </div>
                                <div className={e.active ? "news-container__status news-container__status--active" : "news-container__status"}>
                                    {e.active ? t('translations:active') : t('translations:hidden')}
                                </div>
                                <div className="news-container__action-container">
                                    <form  className="noDefaultStyle" method="post">
                                        <input type="hidden" name="_method" value="PUT" />
                                        <input type="hidden" name="_token" value="v16uXHjVe0pYsTbSZd4wGjHo2t8fCTuJgaOlFcI6" autoComplete="off" />
                                        <button onClick={(e) => handleSet(e, i)} type="submit" className="noDefaultStyle news-container__action-fix">
                                            {t('translations:set')}
                                        </button>
                                    </form>
                                    <form  className="noDefaultStyle"  method="post">
                                        <input type="hidden" name="_method" value="PUT" />
                                        <input type="hidden" name="_token" value="v16uXHjVe0pYsTbSZd4wGjHo2t8fCTuJgaOlFcI6" autoComplete="off" />
                                        <button onClick={(event) => handleModify(event, e, i)} type="submit" style={{background:'purple'}} className="noDefaultStyle news-container__action-fix">
                                            {t('translations:modify')}
                                        </button>
                                    </form>
                                    <form className="noDefaultStyle">
                                        <input type="hidden" name="_method" value="DELETE" />
                                        <input type="hidden" name="_token" value="v16uXHjVe0pYsTbSZd4wGjHo2t8fCTuJgaOlFcI6" autoComplete="off" />
                                        <button onClick={(e) => handleDelete(e, i)} type="submit" className="noDefaultStyle news-container__action-delete">
                                            {t('translations:delete')}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {newsState === 'load' && (
                <form className="account-row__user-info">
                    <input type="hidden" name="_token" value="iKf7WpIL2qicOGt2sZWSKFoSMPLseElKWvjQ7zXK" autoComplete="off" />
                    <div className="form-container__item-container" data-characters="30">
                        <label className="form-container__item-title" htmlFor="A1MMDYSPUH">
                            {t('translations:title')}
                        </label>

                        <input
                            type="text"
                            name="title"
                            onChange={(e) => setNewsInputs({ ...newsInputs, [e.target.name]: e.target.value })}
                            value={newsInputs.title}
                            className="form-container__item-input"
                            id="A1MMDYSPUH"
                        />

                        <div className="form-container__item-bag">
                            <p className="form-container__item-error">
                            </p>
                            <span className="form-container__item-counter">
                                30
                            </span>
                        </div>
                    </div>

                    <div className="form-container__item-container" data-characters="250">
                        <label className="form-container__item-title" htmlFor="54ZUDUL3D8">
                            {t('translations:content')}
                        </label>

                        <textarea
                            type="text"
                            name="content"
                            style={{minHeight: '200px'}}
                            onChange={(e) => setNewsInputs({ ...newsInputs, [e.target.name]: e.target.value })}
                            value={newsInputs.content}
                            className="form-container__item-input"
                            id="54ZUDUL3D8"
                        />

                        <div className="form-container__item-bag">
                            <p className="form-container__item-error">
                            </p>
                            <span className="form-container__item-counter">
                            </span>
                        </div>
                    </div>

                    <div className="form-container__image-preview-container mt-2">
                        <input
                            onChange={(e) => handleFileChange(e)}
                            type="file"
                            accept="image/*"
                            className="displayNone"
                            id="upload_file_btn"
                            name="image"
                        />
                        <label htmlFor="upload_file_btn" className="form-container__image-label-container">
                            <div className="form-container__image-preview-empty-image" id="upload_file_empty">
                                <img src={require("../../img/icon/image-preview.png")} className="form-container__image-preview-icon" alt={t('translations:click_to_upload')} />
                                <p>
                                    {t('translations:click_to_upload')}
                                </p>
                            </div>
                            <div className="form-container__image-preview-image-uploaded displayNone" id="upload_file_uploaded">
                                <img src="" className="form-container__image-preview-image" id="upload_file_img" alt={t('translations:uploaded_image')} />
                                <span className="form-container__image-preview-change">
                                    {t('translations:click_to_change')}
                                </span>
                                <button type="button" id="upload_file_delete" className="card-container__action-button">
                                    {t('translations:delete')}
                                </button>
                            </div>
                        </label>
                    </div>
                </form>



            )}

            {newsState === 'load' && (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                    <button onClick={handleSubmit} style={{ margin: '10px 5px' }} type="submit" className="noDefaultStyle upload-image__btn-submit">
                        {t('translations:save')}
                    </button>
                    <button onClick={() => { setNewsState('menu') }} style={{ margin: '10px 5px' }} type="button" className="noDefaultStyle account-form__button">
                        {t('translations:back')}
                    </button>
                </div>
            )}

            {newsState === 'menu' && newsArray?.length > 0 && (
                <span className="line">
                </span>
            )}

            <div className="account-row__user-manage">
                {newsState === 'menu' && (
                    <div className="parent-div" style={{ width: '100%', position: 'relative' }}>
                        <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex: '500' }} className="noDefaultStyle account-row__manage-row">
                        </div>
                        <div onClick={() => setNewsState('load')} style={{ cursor: 'pointer', position: 'relative', zIndex: '1000' }} className="noDefaultStyle account-row__manage-row2">
                            <h3 style={{ fontSize: '16px', position: 'relative' }}>
                                {t('translations:create')}
                            </h3>
                            <span>
                                {t('translations:main_news')}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            {newsState === 'menu' && (
                <button onClick={() => { setSelectedOption('pagesettings') }} style={{ margin: '10px 5px' }} type="button" className="noDefaultStyle account-form__button">
                    {t('translations:back')}
                </button>
            )}
        </div>

    )
}