import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Modal from "./Addons/Modal";
import axios from 'axios'
import { API_URL } from "../Auth/authConstants2";
import { useTranslation } from "react-i18next";


export default function ResetLink() {

    const { t } = useTranslation();



    const [bodyState, setBodyState] = useState({
        request: "",
        email: ""
    })


    useEffect(() => {
        const queries = (window.location.pathname).split('/')[2].split('&');

        const id = queries[0].split('=')[1];
        const email = queries[1].split('=')[1];

        setBodyState({ request: id, email });

    }, [])


    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });



    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }


    const [errorResponse, setErrorResponse] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {            
            const lng = localStorage.getItem('language');
            const result = await axios.post(`${API_URL}/reset-password`, {...bodyState, lang: lng});
            console.log(result)
            setErrorResponse(result.data)
        } catch (err) {
            setErrorResponse(err);
        }
    }



    window.addEventListener("scroll", changeNavbar);


    return (
        <div >
            <div className="notification-container unselectable" id="notification-container">
            </div>
            <div className="body__content" id="body__content">
                <Navbar setIsModal={setIsModal} navbar={navbar} />

                
                <div className="reset-form-container">
                    <div style={{ zIndex: '400', background: 'transparent', position:'relative' }} className="centerFlexbox modal-container modal-container--initial-animation">
                        <div className="modal-container__window">
                            <div className="modal-container__window-space">

                                <form className="user-session__form-container user-session__width" style={{ display: 'flex', position: 'relative', backgroundImage: 'url("../../img/background/table2.png") !important' }} id="session-form-login">
                                    <input type="hidden" name="_token" autoComplete="off" />
                                    <div className="section-title user-session__width">

                                        <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%', float: 'left' }} src={require("../img/props/title.png")} className="news-container__prop" />
                                        <div style={{ display: 'flex' }}><span style={{ marginTop: '2px' }} className="noDefaultStyle news-container__control-arrow"></span>{t('translations:reset_password')}</div>

                                        <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%' }} src={require("../img/props/title2.png")} className="section-title__prop" />
                                    </div>

                        <button onClick={(e) => handleSubmit(e)} style={{ background: 'none', marginTop:'20px', marginBottom: '50px' }} type="submit" className="noDefaultStyle user-session__button">

<img style={{ filter: 'sepia(1) saturate(6) hue-rotate(-50deg) brightness(0.8) contrast(1.3)', position: 'absolute' }} src={require("../img/props/bttn-copy.png")}></img>
<span style={{ zIndex: '100' }}>{t('translations:get_new_password')}</span>
</button>
                        {errorResponse && <div style={{  padding: '10px 20px', textAlign:'center', color: errorResponse?.includes('success') ? "green" : "red", padding: 'auto 20px'  }} className="error-message">{t(`translations:${errorResponse}`)}</div>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    )
}