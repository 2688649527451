import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer (){

    
    const { t, i18n } = useTranslation();


    return (        
        <footer className="footer">
        <div className="footer-elements">
            <img src={require("../img/social/logo.gif")} className="footer-logo" />
            <div className="footer-links">
                <Link style={{cursor: 'pointer'}} to="/" className="noDefaultStyle footer-link">
                {t('translations:home')}
                </Link>
                <Link to="/terms-of-service" className="noDefaultStyle footer-link footer-link--gold">
                    {t('translations:terms')}
                </Link>
                <Link to="/privacy-policy" className="noDefaultStyle footer-link">
                    {t('translations:policy_privacy')}
                </Link>
                <a className="noDefaultStyle footer-link" target="_blank" href="https://metin2pserver.net/" title="Best Metin2 Pservers Toplist" data-id="2279">Metin2Pserver.net</a>
            </div>
        </div>
    </footer>

    )
}