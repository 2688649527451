import React, { useEffect } from "react";



export default function Loading({request = false}) {

    useEffect(() => {
    if((window.location.href).includes('www')){
        window.location.href = 'https://cypher2global.com';
      }
    }, [])



    return (
        <div style={{ position: 'absolute', top:'0', minWidth: '100%', minHeight:'100vh', zIndex: '1000000'  }}>
            {!request && <div style={{minHeight: '100vh', minWidth:'100%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center', }} className="reset-form-container">
             <img style={{ width: '15%', marginTop: '-75px' }} src={require('../../img/background/loading.gif')} />
                <div style={{position: 'absolute'}}>
                    <div style={{marginTop: `calc(300px + ${window.innerHeight > 768 ? window.innerHeight * 5 / 100 : (-50)}px)`}} className="loader"></div>
                </div>
            </div>}
            {request && <div style={{minHeight: '100vh', background: "#60101094", minWidth:'100%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center', }} className="">            
                <div style={{position: 'absolute'}}>
                    <div style={{marginTop: '20px'}} className="loader"></div>
                </div>
            </div>}
        </div>
    )
}