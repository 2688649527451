import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Modal from "../Addons/Modal";
import { useTranslation } from "react-i18next";
import NewsCard from "./NewsCard";
import { Link } from "react-router-dom";
import { API_URL } from "../../Auth/authConstants2";
import axios from "axios";
import Loading from "../Addons/Loading";


export default function NewsContent() {



    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });

    useEffect(() => {
        if (isModal.active) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModal])

    const { t } = useTranslation();


    const [news, setNews] = useState([]);
    const [currentNew, setCurrentNew] = useState(null);

    useEffect(() => {
        axios.get(`${API_URL}/get-news`, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            setNews((response.data).reverse())
            const newData = (response.data).filter((e, i) => i + 1 == (window.location.href).split('=')[1]);
            setCurrentNew(newData[0]);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

    }



    window.addEventListener("scroll", changeNavbar);



    return (


        currentNew?.title && news?.length ? <div className="body__content" id="body__content">
            <Navbar setIsModal={setIsModal} navbar={navbar} />

            <section className="download-section">

                <div className="section-title">
                    <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                    <h2>
                        {t('translations:latest_news')}
                    </h2>
                    <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                </div>

                <div style={{ backgroundImage: `url(${require('../../img/background/table.png')}` }} class=" table-container__table-container news-div-container">
                    <div className="news-content-container" >
                        <div style={{ margin: 'auto' }} className="news-container__event-banner">
                            <img src={require("../../img/background/event.png")} className="news-container__event-image" />
                            <h3 style={{ textAlign: 'center' }} className="news-container__event-name">
                                {currentNew?.title}
                            </h3>
                        </div>

                        <div style={{ width: '100%', borderTop: '1px solid #d8b482', marginTop:'20px' }} className="news-container__event-description">
                            <p style={{ maxWidth: '100%' }}>
                                {currentNew?.content}
                            </p>
                        </div>
                        <div style={{ margin: '30px auto' }} className="cards-container__card2">
                            <img className="cards-container__item-image" src={`${API_URL + currentNew.imgURL}`}></img>
                        </div>
                    </div>
                </div>

                <span className="line">
                </span>
                {news?.length > 1 && <div className="section-title">
                    <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                    <h2>
                        {t('translations:more_news')}
                    </h2>
                    <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                </div>}

                {news?.length > 1 && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div className="news-content-container" >
                        {
                            news?.length && (news.filter((e, i) => i !== (window.location.href).split('=')[1] - 1))?.slice(0, 4).map((e, i) => {
                                return (
                                    <div key={i + 'new'} style={{ maxHeight: '400px' }}>
                                        <NewsCard newData={e} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>}

                <div className="section-title" style={{ textTransform: 'initial !important' }}>
                    <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                    <Link style={{ textTransform: 'none !important' }} to="/news" className="noDefaultStyle table-container__show-more">
                        <span style={{ textTransform: 'none !important' }} className="noDefaultStyle news-container__control-arrow"></span>{t('translations:see_all')}
                    </Link>
                    <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                </div>

            </section>
            <Footer />
            <Modal isModal={isModal} setIsModal={setIsModal} />
        </div> : <Loading />
    )
}