import React, { useState, useEffect, useRef } from "react";
import "../../Styles/components/custom.slider.css";

export default function CustomCarousel({ children, activeIndex, setActiveIndex, onPrev, onNext, setClicked, clicked, setSlideDone, slideDone }) {

  const timeoutRef = useRef(null); // Use a ref to store the timeout ID

  // Effect to handle the autoplay timeout
  useEffect(() => {
    if(!slideDone || clicked){
      AutoPlayStop();
      setSlideDone(false)
    }
    if(slideDone && !clicked){     
      setSlideDone(true) 
      AutoPlayStart();
    }


    if (slideDone && !clicked) {
      setSlideDone(true);

      // Set a new timeout and store its ID in the ref
      timeoutRef.current = setTimeout(() => {
        onNext();
        setSlideDone(true);
      }, 5000);

    }
    // Cleanup function to clear the timeout on component unmount or before setting a new one
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [slideDone, onNext, clicked]);

  // Effect to restart the timeout when activeIndex changes
  useEffect(() => {
    // Restart the autoplay timeout whenever activeIndex changes
    setSlideDone(true); // This will trigger the autoplay effect


    // Cleanup function to clear the timeout on component unmount or before restarting
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [activeIndex]);


  const AutoPlayStop = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      onNext();
      setSlideDone(true);
    }
  };

  const handlePrev = () => {
    AutoPlayStop();
    onPrev();
    setSlideDone(true); // Restart the autoplay
  };

  const handleNext = () => {
    setClicked(false);
    AutoPlayStart();
    onNext();
    setSlideDone(true); // Restart the autoplay
  };



  const handleClickItem = () => {
    AutoPlayStop();
    setClicked(true);
  };


  //onMouseEnter={AutoPlayStop}
  //onMouseLeave={clicked ? ()=> {setSlideDone(false)} : AutoPlayStart}
  return (
    <div
      className="container__slider"
    >
      {children?.map((item, index) => (
        <div
          className={"slider__item slider__item-active-" + (activeIndex + 1)}
          key={index}
          onClick={handleClickItem}
        >
          {item}
        </div>
      ))}

    </div>
  );
}














// import React, { useState, useEffect } from "react";
// import "../../Styles/components/custom.slider.css";

// export default function CustomCarousel({ children, activeIndex,setActiveIndex, onPrev, onNext }) {
//   const [slideDone, setSlideDone] = useState(true);
//   const [timeID, setTimeID] = useState(null);

//   useEffect(() => {
//     if (slideDone) {
//       setSlideDone(false);
//       setTimeID(
//         setTimeout(() => {
//           onNext();
//           setSlideDone(true);
//         }, 5000)
//       );
//     }
//   }, [slideDone, onNext]);

//   const AutoPlayStop = () => {
//     console.log(timeID)
//     if (timeID > 0) {
//       clearTimeout(timeID);
//       setSlideDone(false);
//     }
//   };

//   const AutoPlayStart = () => {
//     console.log(timeID)
//     if (!slideDone) {
//       setSlideDone(true);
//     }
//   };

//   return (
//     <div
//       className="container__slider"
//       onMouseEnter={AutoPlayStop}
//       onMouseLeave={AutoPlayStart}
//     >
//       {children?.map((item, index) => (
//         <div
//           className={"slider__item slider__item-active-" + (activeIndex + 1)}
//           key={index}
//         >
//           {item}
//         </div>
//       ))}
//     </div>
//   );
// }










