import React, { useEffect, useState } from "react";
import { API_URL } from "../../Auth/authConstants2";
import { useAuth } from "../../Auth/AuthProvider";
import { useTranslation } from "react-i18next";

export default function ChangeMail({ setSelectedOption, message, setMessage, setLoading }) {


    const auth = useAuth();
    const { t } = useTranslation();

    const [userData, setUserData] = useState({
        email: '',
        password: '',
    })



    const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };



    const [errors, setErrors] = useState({
        password: '',        // Updated from currentPassword to password
        email: ''
    });


    const [updateStates, setUpdateStates] = useState(false);

    const validateForm = () => {
        const newErrors = {};


        if (!userData.email) {
          newErrors.email = t('translations:email_required');
        } else if (!isValidEmail(userData.email)) {
          newErrors.email = t('translations:invalid_email_format');
        }
      
        if (!userData.password) {
          newErrors.password = t('translations:password_required');
        }
        Object.keys(newErrors).length > 0 ? setErrors(newErrors) : setErrors({});

        setUpdateStates(!updateStates);

        return Object.keys(newErrors).length === 0;
    };




    async function handleChangeMail(e) {
        e.preventDefault();
        let validate = validateForm();

        if (validate) {
            setLoading(true);

            try {
                const response = await fetch(`${API_URL}/changemail`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.getAccessToken()}`,
                    },
                    body: JSON.stringify({
                        id: auth.getUser()?.id,
                        email: userData.email,
                        password: userData.password
                    })
                });
                if (response.ok) {
                    auth.getUpdate();
                    setUserData({
                        email: '',
                        password: '',
                    })
                    setMessage({type: 'success', message:'email_changed'})
                }else {
                    setMessage({type: 'error', message:(await response.json()).error})
                }
            } catch (error) {
                console.log(error);
            }
        }
        setLoading(false);
    }




    return (


        <div className="account-row-2">
            <div className="section-title">
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_alt')} />
                <h2>
                    {t('translations:my_email')}
                </h2>
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_alt')} />
            </div>

            <div className="account-row__user-info">
                <div className="account-row__user-row">
                    <div className="account-row__user-title">
                        {t('translations:email')}
                    </div>
                    <div className="account-row__user-description">
                        {auth.getUser()?.email || t('translations:na')}
                    </div>
                </div>
            </div>

            <span className="line">
            </span>

            <div className="account-row__user-info">
                <form className="account-form">
                    <input type="hidden" name="_method" value="PUT" />
                    <input type="hidden" name="_token" value="HcCRTrzJXWEqwdppC05kRvE9mOyT3Ta0qpJo7BSP" autoComplete="off" />
                    <div className="account-form__block">
                        <label className="account-form__title">
                            {t('translations:new_email')}
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })}
                            className="noDefaultStyle account-form__input"
                            placeholder={t('translations:new_email_placeholder')}
                        />
                    </div>
                    <div className="account-form__block">
                        <label className="account-form__title">
                            {t('translations:password')}
                        </label>
                        <input
                            type="password"
                            name="password"
                            value={userData.password}
                            onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })}
                            className="noDefaultStyle account-form__input"
                            placeholder={t('translations:password_placeholder')}
                        />
                    </div>
                    <div className="account-form__block">
                        <label style={{ color: '#d8869c' }} className="account-form__title">
                            {errors?.email}
                        </label>
                        <label style={{ color: '#d8869c' }} className="account-form__title">
                            {errors?.password}
                        </label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            onClick={(e) => handleChangeMail(e)}
                            style={{ margin: '10px 5px' }}
                            type="submit"
                            className="noDefaultStyle account-form__button"
                        >
                            {t('translations:confirm')}
                        </button>
                        <button
                            onClick={() => { setSelectedOption('dashboard') }}
                            style={{ margin: '10px 5px' }}
                            type="button"
                            className="noDefaultStyle account-form__button"
                        >
                            {t('translations:back')}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    )
}