import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Modal from "./Addons/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { getElements } from '../Actions';
import Counter from "./Addons/Odometer";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Auth/AuthProvider";
import axios from 'axios'
import CustomSlider from "./Addons/Carousel";
import { API_URL } from "../Auth/authConstants2";
import Loading from "./Addons/Loading";
import SwiperComponent from "./Addons/Swiper";
import DiscordButton from "./Addons/DiscordButton";



export default function Home() {

    const { t } = useTranslation();
    let auth = useAuth();

    const dispatch = useDispatch();
    const statistics = useSelector((state) => state.elements);



    useEffect(() => {
        if (auth.getUser()) {
            auth.checkTokens();
        }
    }, [])



    // const lng = navigator.language;



    const [news, setNews] = useState({})
    const [cardsArray, setCardsArray] = useState([])

    useEffect(() => {
        dispatch(getElements());

        axios.get(`${API_URL}/get-active-news`, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            setNews(response.data)
        }).catch(err => {
            console.log(err);
        })


        axios.get(`${API_URL}/get-cards`, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            setCardsArray(response.data)
        }).catch(err => {
            console.log(err);
        })


        return window.scroll(0, 0);

    }, [dispatch]);


    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });


    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (isModal.active || loading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModal, loading])


    const [navbar, setNavbar] = useState(false);
    const [upDownButton, setUpDownButton] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

        if (window.scrollY >= 50) {
            setUpDownButton(true);
        } else {
            setUpDownButton(false);
        }

    }



    function upDown(e) {
        e.preventDefault();
        let current = window.scrollY;
        if (current <= 50) {
            return window.scroll(0, 2000);
        } else {
            return window.scroll(0, 0);
        }
    }





    const [activeIndex, setActiveIndex] = useState(0);
    const [slideDone, setSlideDone] = useState(true);
    const [clicked, setClicked] = useState(false);

    const slideNext = () => {
        setActiveIndex((prevIndex) =>
            prevIndex >= (statistics?.imagesData).length - 1 ? 0 : prevIndex + 1
        );
        setClicked(false);
        setSlideDone(true);
        
    };

    const slidePrev = () => {
        setActiveIndex((prevIndex) =>
            prevIndex <= 0 ? (statistics?.imagesData).length - 1 : prevIndex - 1
        );
        setClicked(true);
        setSlideDone(false);
    };



    window.addEventListener("scroll", changeNavbar);

    //window
    //rgba(41, 2, 2, 1)


    return (
        (statistics?.playersOnline) ? <div>
            <div className="notification-container unselectable" id="notification-container">
            </div>
            <div className="body__content" id="body__content">
                <img style={{ display: window.innerWidth < 500 ? "none" : "initial", position: 'fixed', right: '20px', zIndex: '1000', bottom: '20px', cursor: 'pointer' }} src={upDownButton ? require("../img/props/arrow-up.png") : require("../img/props/arrow-down.png")} onClick={(e) => upDown(e)} />
                <Navbar setIsModal={setIsModal} navbar={navbar} />
                <header className="body-main-header">
                    <div className="header-video-cointainer" >
                        <div className="header-video-shadow">
                        </div>
                        <video controls={false} style={{ position: 'absolute', objectFit: 'cover', filter: 'brightness(1.1) contrast(1.2)', zIndex: '5' }} loop muted={true} autoPlay={true}>
                            <source src={require('../img/background/banner-normal.webm')} type="video/webm" />
                        </video>
                        <div style={{ position: 'absolute', objectFit: 'cover',  zIndex: '20', height: '100%', width: '100%' }} >

                        </div>
                    </div>
                    <div className="body-main-header__counters">
                        <div className="player-counters">
                            <Link to="/download" style={{ fontWeight: '600' }} className="unselectable noDefaultStyle player-counters__download">
                                {t('translations:download')}
                            </Link>
                            <div style={{ position: 'relative' }} className="player-counters__stats">
                                <img style={{ position: 'absolute', top: '-12px', zIndex: 500 }} src={require('../img/background/jewel2.png')} />
                                <span className="player-counters__payer-counter" style={{ zIndex: 1000, position: 'relative' }}>
                                    {/*(statistics?.playersOnline)?.length*/}
                                    <Counter online={(statistics?.playersOnline)?.length} />
                                </span>
                                <p className="player-counters__stats-title">
                                    {t('translations:online_players')}
                                </p>
                            </div>
                            <div style={{ position: 'relative' }} className="player-counters__stats">
                                <img style={{ position: 'absolute', top: '-12px', zIndex: 500 }} src={require('../img/background/jewel.png')} />
                                <span className="player-counters__payer-counter" style={{ zIndex: 1000, position: 'relative' }}>
                                    {(statistics?.playersOnline24h)?.length}
                                </span>
                                <p className="player-counters__stats-title">
                                    {t('translations:online_players')} (24h)
                                </p>
                            </div>
                            <div style={{ position: 'relative' }} className="player-counters__stats">
                                <img style={{ position: 'absolute', top: '-12px', zIndex: 500 }} src={require('../img/background/jewel2.png')} />
                                <span className="player-counters__payer-counter" style={{ zIndex: 1000, position: 'relative' }}>
                                    <Counter online={(statistics?.privateShop)?.length} />
                                </span>
                                <p className="player-counters__stats-title">
                                    {t('translations:offline_shops')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="news-container">
                        <div className="news-container__news-block">
                            <div className="news-container__generic-container" id="news-container__views-container">
                                <CustomSlider
                                    activeIndex={activeIndex}
                                    setActiveIndex={setActiveIndex}
                                    onNext={slideNext}
                                    onPrev={slidePrev}
                                    setClicked={setClicked}
                                    clicked={clicked}
                                    setSlideDone={setSlideDone}
                                    slideDone={slideDone}
                                >
                                    {(statistics?.imagesData)?.map((image, index) => {
                                        return image.type == 'image' ? <img key={index + 'img'} src={API_URL + image.imgURL} alt={image.imgAlt} /> : <video 
                                        onPlay={() => setClicked(true)} onEnded={()=> slideNext()} key={index + 'video'} controls style={{ height: '320px', width: '600px' }}><source src={API_URL + image.imgURL} type="video/mp4" /></video>;
                                    })}
                                </CustomSlider>
                            </div>

                            <div className="news-container__controls-container">
                                <button
                                    className="noDefaultStyle news-container__control-arrow"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        slidePrev();
                                    }}
                                >

                                </button>

                                <div className="carousel-indicators">
                                    {(statistics?.imagesData)?.map((_, index) => (
                                        <div key={index + 'imagesData'} style={{
                                            width: "15px",
                                            height: "15px", position: 'relative'
                                        }}>
                                            <div className={activeIndex === index ? "container__slider__links-small-active_background" : ""}></div>
                                            <button
                                                key={index}
                                                className={
                                                    activeIndex === index
                                                        ? "container__slider__links-small container__slider__links-small-active"
                                                        : "container__slider__links-small"
                                                }
                                                onClick={() => setActiveIndex(index)}
                                            ></button></div>
                                    ))}
                                </div>
                                <button
                                    className="noDefaultStyle news-container__control-arrow"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        slideNext();
                                    }}
                                >

                                </button>
                            </div>
                        </div>

                        <div className="news-container__social-container">
                            <a href="https://discord.gg/cypher2" className="noDefaultStyle news-container__social-block" target="_blank">
                                <img src={require("../img/social/dsc.png")} className="news-container__social-icon" />
                            </a>
                            <a href="https://www.facebook.com/share/g/6uB81BdmQzsk2DSj/" className="noDefaultStyle news-container__social-block" target="_blank">
                                <img src={require("../img/social/fb.png")} className="news-container__social-icon" />
                            </a>
                            <a href="https://www.tiktok.com/@cypherm2" className="noDefaultStyle news-container__social-block" target="_blank">
                                <img src={require("../img/social/tiktok.png")} className="news-container__social-icon" />
                            </a>
                            <a href="https://www.youtube.com/@CypherM2" className="noDefaultStyle news-container__social-block" target="_blank">
                                <img src={require("../img/social/youtube.png")} className="news-container__social-icon" />
                            </a>
                        </div>

                        <div className="news-container__news-block">
                            <div className="news-container__generic-container">
                                <div className="news-container__title">
                                    <img src={require("../img/props/title.png")} className="news-container__prop" />
                                    <h2>
                                        {t('translations:latest_news')}
                                    </h2>
                                    <img src={require("../img/props/title.png")} className="news-container__prop" />
                                </div>
                                <div className="news-container__event-banner">
                                    <img src={require("../img/background/event.png")} className="news-container__event-image" />
                                    <h3 className="news-container__event-name">
                                        {news?.title}
                                    </h3>
                                </div>
                                <div className="news-container__event-description">
                                    <p style={{
                                        color: '#fdacac', fontSize: '14px',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 5,
                                        WebkitBoxOrient: 'vertical',
                                        lineHeight: '1.4em',
                                        textOverflow: 'ellipsis',
                                    }}>
                                        {news?.content}
                                    </p>
                                </div>
                                <div style={{ bottom: '-20px', left: '50%', transform: 'translate(-50%)' }} className="absolute ">
                                    <Link to={`/news/article?id=${(news?.imgAlt)?.split('-')[1]}`} className="noDefaultStyle table-container__show-more">
                                        <span className="noDefaultStyle news-container__control-arrow"></span>{t('translations:show_full_new')}
                                    </Link>
                                </div>
                            </div>



                        </div>
                    </div>
                </header>




                <div className="table-container">
                    <div className="section-title">
                        <img src={require("../img/props/section-title.png")} className="section-title__prop" />
                        <h2>
                            {t('translations:server_ranks')}
                        </h2>
                        <img src={require("../img/props/section-title.png")} className="section-title__prop" />
                    </div>

                    <div className="table-container__tables-grid">
                        <div className="table-container__table-container">

                            <div className="table-container__table-details">
                                <div className="table-container__details-title">
                                    <h3>
                                        {t('translations:players_rank')}
                                    </h3>
                                </div>
                                <div className="table-container__details-stats">
                                    <span className="table-container__details-icon">
                                    </span>
                                    <p>
                                        <span>{(statistics?.topPlayersTable)?.length}</span> {t('translations:created')}
                                    </p>
                                </div>
                            </div>
                            <div className="table-container__table-row table-container__table-head">
                                <span className="table-container__table-title">
                                    #
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:player_nick')}
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:guild')}
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:kingdom')}
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:level')}
                                </span>
                            </div>
                            {
                                (statistics?.topPlayersTable)?.slice(0, 10).map((e, i) => {
                                    return (
                                        <div key={"players" + i} className="table-container__table-row">
                                            <span className="table-container__table-text">
                                                {i < 9 ? '0' + (i + 1) : (i + 1)}
                                            </span>
                                            <span className="table-container__table-text">
                                                {e.name}
                                            </span>
                                            <span className="table-container__table-text">
                                                <span className="table-container__table-ellipse">
                                                </span>
                                                <span>
                                                    {e.guild_name || "N/A"}
                                                </span>
                                            </span>
                                            <span style={{ marginLeft: '15px' }} className="table-container__table-text ">
                                                {e?.empire && <img src={require(`../img/empire/${e.empire}.png`)} className="tables-container__table-flag" alt="Jinno" title="Jinno" />}

                                            </span>
                                            <span className="table-container__table-text">
                                                {e.level}
                                            </span>
                                        </div>

                                    )
                                })
                            }


                            <div className="table-container__table-action">
                                <Link to="/rank/players" className="noDefaultStyle table-container__show-more">
                                    <span className="noDefaultStyle news-container__control-arrow"></span>{t('translations:show_full_rank')}
                                </Link>
                            </div>
                        </div>

                        <div className="table-container__table-container">

                            <div className="table-container__table-details">
                                <div className="table-container__details-title">
                                    <h3>
                                        {t('translations:guilds_rank')}
                                    </h3>
                                </div>
                                <div className="table-container__details-stats">
                                    <span className="table-container__details-icon">
                                    </span>
                                    <p>
                                        <span>{(statistics?.topGuildsTable)?.length}</span> {t('translations:created')}
                                    </p>
                                </div>
                            </div>
                            <div className="table-container__table-row table-container__table-head">
                                <span className="table-container__table-title">
                                    #
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:guild_name')}
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:win_losses')}
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:kingdom')}
                                </span>
                                <span className="table-container__table-title">
                                    {t('translations:level')}
                                </span>
                            </div>


                            {(statistics?.topGuildsTable)?.slice(0, 10).map((e, i) => {
                                return (
                                    <div key={"guild" + i} className="table-container__table-row">
                                        <span className="table-container__table-text">
                                            {i < 9 ? '0' + (i + 1) : (i + 1)}
                                        </span>

                                        <span className="table-container__table-text">
                                            <span className="table-container__table-ellipse">
                                            </span>
                                            <span>
                                                {e.name}
                                            </span>
                                        </span>
                                        <span style={{ marginLeft: '30px' }} className="table-container__table-text ">
                                            {e.win + '/' + e.loss}
                                        </span>
                                        <span style={{ marginLeft: '15px' }} className="table-container__table-text">
                                            {e?.empire && <img src={require(`../img/empire/${e.empire}.png`)} className="tables-container__table-flag" alt="Jinno" title="Jinno" />}
                                        </span>
                                        <span className="table-container__table-text">
                                            {e.level}
                                        </span>
                                    </div>
                                )
                            })}


                            <div className="table-container__table-action">
                                <Link to="/rank/guilds" className="noDefaultStyle table-container__show-more">
                                    <span className="noDefaultStyle news-container__control-arrow"></span>{t('translations:show_full_rank')}
                                </Link>
                            </div>
                        </div>
                    </div>

                    <span className="line">
                    </span>

                    <div className="card-title">
                        <h2>
                            {t('translations:discover_more')}
                        </h2>
                        <p>
                            {t('translations:be_part')}
                        </p>
                    </div>
                    <div className="swiper-container" style={{ margin: '20px 0px' }}>
                        {cardsArray?.length && <SwiperComponent items={cardsArray} />}

                        {/*cardsArray?.map((e, i) => {
                            return (
                                <div key={i + 'new'} className="cards-container__card">
                                    <span className="cards-container__new">
                                        New
                                    </span>
                                    <img src={`${API_URL + e.imgURL}`} className="cards-container__item-image" />
                                    <div className="cards-container__card-description">
                                        <p>
                                            {e.title}
                                        </p>
                                    </div>
                                </div>
                            )
                        })*/}
                    </div>
                </div>
                <DiscordButton />
                <Footer />
                {loading && <Loading request={true} />}
                <Modal isModal={isModal} setIsModal={setIsModal} setLoading={setLoading} />
            </div>
        </div> : <Loading />

    )
}