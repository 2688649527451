import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Login from "../Login";
import Register from "../Register";
import Reset from "../Reset";





export default function Modal({ isModal, setIsModal, setLoading }) {


    const [closeModal, setCloseModal] = useState(false);

    useEffect(() => {
        if (!isModal.active) {
            setTimeout(() => {
                setCloseModal(true);
            }, 200)
        } else {
            setCloseModal(false);
        }
    }, [isModal.active])



    const handleModal = () => {
        setIsModal({ ...isModal, active: false })
    }


    return (
        <div className={isModal.active ? "centerFlexbox modal-container modal-container--initial-animation" : closeModal ? "modal-bucket" : "centerFlexbox modal-container modal-container--delete"} id="modal-bucket">
            <div className="modal-container__window">
                <div className="centerYFlexbox modal-container__close">
                </div>

                <div className="modal-container__window-space">

                    {isModal.type === 'login' ? (
                        <Login handleModal={handleModal} setIsModal={setIsModal} setLoading={setLoading} />
                    ) : isModal.type === 'create' ? (
                        <Register handleModal={handleModal} setIsModal={setIsModal} setLoading={setLoading} />
                    ): <Reset  handleModal={handleModal} setIsModal={setIsModal} setLoading={setLoading} />}
                </div>

            </div>
        </div>
    )
}