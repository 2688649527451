import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Modal from "./Addons/Modal";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";
import NewsCard from "./News/NewsCard";
import { API_URL } from "../Auth/authConstants2";
import axios from "axios";
import Loading from "./Addons/Loading";
import Paginated from "./Addons/Paginated";
import DiscordButton from "./Addons/DiscordButton";


export default function News() {


    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });

    const { t } = useTranslation();


    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (isModal.active || loading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModal, loading])


    
    const [news, setNews] = useState([]);

    useEffect(() => {

        axios.get(`${API_URL}/get-news`, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            setNews((response.data).reverse())
        }).catch(err => {
            console.log(err);
        })
    }, [])


    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

    }

    

    const [currentPage, setCurrentPage] = useState(1);
    const [elemPerPage, setElemPerPage] = useState(8);
    const [orderElem, setOrderElem] = useState(false);
    const indexOfLastCharacter = currentPage * elemPerPage;
    const indexOfFirstCharacter = indexOfLastCharacter - elemPerPage;
    const currentElem = news?.slice(indexOfFirstCharacter, indexOfLastCharacter);


    const paginado = (pages) => {
        setCurrentPage(pages)
    }



    window.addEventListener("scroll", changeNavbar);


    return (

        news?.length ? <div className="body__content" id="body__content">
            <Navbar setIsModal={setIsModal} navbar={navbar} />

            <section className="download-section">

                <div className="section-title">
                    <img src={require("../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                    <h2>
                        {t('translations:latest_news')}
                    </h2>
                    <img src={require("../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                </div>


                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div className="news-cards-container" >
                    {
                        currentElem?.length && currentElem.map((e, i) => {
                            return (
                                <div key={i+'new'} style={{ maxHeight: '400px' }}>
                                    <NewsCard newData={e} />
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
                
                <div className="pagination__column-container">
                        <div className="controls-type-basic__pagination unselectable">
                            <Paginated elemPerPage={elemPerPage} statistics={news?.length} paginado={paginado} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                        </div>
                        <div className="pagination__count">
                            {t('translations:showing_range', { start: indexOfFirstCharacter + 1, end: ((currentPage - 1) * 8) + currentElem?.length, total: news?.length })}
                        </div>
                    </div>
            </section>


            <DiscordButton />

            <Footer />
            {loading && <Loading request={true}/>}

            <Modal isModal={isModal} setIsModal={setIsModal} setLoading={setLoading} />
        </div> : <Loading />
    )
}