import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";



export default function AlertModal({ message, setMessage }) {
    const { t, i18n } = useTranslation();


    const [closeModal, setCloseModal] = useState(false);

    const [currentMessage, setCurrentMessage] = useState(false);
    (message.message).length > 0 && !currentMessage && setCurrentMessage(message);

    useEffect(() => {
        if (!((message.message).length > 0)) {
            setTimeout(() => {
                setCloseModal(true);
            }, 300)
        } else {
            setCloseModal(false);
        }

    }, [(message.message).length])




    const MAX_SIZE_MB = 2;

    return (
        <div style={{ zIndex: '5000' }} className={((message.message).length) ? "centerFlexbox modal-container2 modal-container--initial-animation" : closeModal ? "modal-bucket" : "centerFlexbox modal-container2 modal-container--delete"} id="modal-bucket">
            <div style={{ height: '100vh', display:'flex', alignItems:'center'}}>
            <form className="reset-form-container__form-container">
                <input type="hidden" name="_token" value="nY2h5i4kC5IJ27umkMuSRxYEI8m65BY1N02F599L" autoComplete="off" />
                <div className="reset-form-container__form-title">
                    {currentMessage.type == 'success' ? t(`translations:success:success`) : t('translations:errors:error')}
                </div>

                <div className="reset-form-container__form-row">
                    <div style={{ display: 'flex', color: 'white' }} className="reset-form-container__form-input-container">
                        <span>{currentMessage.type == 'success' ? t('translations:success:' + currentMessage.message) : (currentMessage?.message)?.includes('files_too_large') ? t('translations:files_too_large', {number: MAX_SIZE_MB}) : t('translations:errors:' + currentMessage.message)}</span>
                    </div>

                </div>

                <button onClick={(e) => {
                    e.preventDefault();
                    setMessage({ type: '', message: '' })
                    setTimeout(() => {
                        setCurrentMessage(false);
                    }, 500)
                }} className="noDefaultStyle reset-form-container__button">
                    {t('translations:ok')}
                </button>
            </form>
            </div>
        </div>
    )
}