import axios from 'axios';
import { API_URL } from '../Auth/authConstants2';


export function getElements(){ 
    return async (dispatch)=>{
        let json = await axios.get(`${API_URL}/statistics`)
        return dispatch({
            type: 'GET_ELEMENTS',
            payload: json.data
        })        
    }    
}