import axios from "axios";
import { useState, useEffect } from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";



import "../../Styles/components/custom.swiper.css";
import "swiper/css";
import { API_URL } from "../../Auth/authConstants2";

export default function SwiperComponent({items}) {


  return (
    <div className="swiper">
      <div className="container">
        <div className="swiperContainer">
          <Swiper
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            pagination={{
              el: ".pagination",
              clickable: true,
            }}
            slidesPerView={4}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              "@0.50": {
                slidesPerView: 1.25,
                spaceBetween: 25,
              },
              "@1.00": {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              "@1.25": {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              "@1.50": {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              "@1.75": {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {items?.map((e, i) => (
              <SwiperSlide key={i+'swip'}>
              <div key={i + 'new'} className="cards-container__card">
                  <span className="cards-container__new">
                  </span>
                  <span className="cards-container__newspan">                    
                    New
                  </span>
                  <img src={`${API_URL + e.imgURL}`} className="cards-container__item-image" />
                  <div className="cards-container__card-description">
                      <p>
                          {e.title}
                      </p>
                      <span>
                        {e?.description}
                      </span>
                  </div>
                    </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}