const initialState = {
    elements: [],
}

function rootReducer(state = initialState, action) {
    switch(action.type){
        case 'GET_ELEMENTS': 
            return {
                ...state,
                elements: action.payload,
            }
        default: return state            
    }
}

export default rootReducer;