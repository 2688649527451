import React, { useEffect } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useTranslation } from "react-i18next";


export default function PageSettings({ setSelectedOption }) {
    const auth = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        auth.checkTokens();
    }, [setSelectedOption])

    return (
        <div className="account-row-2">
            
      <div className="section-title">
        <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
        <h2>{t('translations:page_settings')}</h2>
        <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
      </div>

            <span className="line">
            </span>

            <div className="account-row__user-manage">                
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('slidersettings')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:slider')}
                        </h3>
                        <span>
                            {t('translations:change_slider_images')}
                        </span>
                    </div>
                </div>
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('newssettings')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:news')}
                        </h3>
                        <span>
                            {t('translations:change_news')}
                        </span>
                    </div>
                </div>
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('cardssettings')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:cards')}
                        </h3>
                        <span>
                            {t('translations:change_home_cards')}
                        </span>
                    </div>
                </div>
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('downloadssettings')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:downloads')}
                        </h3>
                        <span>
                            {t('translations:modify_downloads')}
                        </span>
                    </div>
                </div>
                <div className="parent-div" style={{ width: '100%', position:'relative' }}>
                    <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex:'500' }} className="noDefaultStyle account-row__manage-row">
                    </div>
                    <div  onClick={() => setSelectedOption('mailssettings')} style={{cursor:'pointer', position:'relative', zIndex: '1000'}} className="noDefaultStyle account-row__manage-row2">
                        <h3 style={{ fontSize: '16px', position:'relative' }}>
                            {t('translations:mass_mail')}
                        </h3>
                        <span>
                            {t('translations:send_mass_mail')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}