import React, { useEffect, useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { API_URL } from "../../Auth/authConstants2";
import axios from 'axios'
import { useTranslation } from "react-i18next";



export default function CardsSettings({ setSelectedOption, message, setMessage }) {

    const [cardsState, setCardsState] = useState('menu')
    const [cardsArray, setCardsArray] = useState([]);
    const [selectedImage, setSelectedImage] = useState(0);
    const [cardsInputs, setCardsInputs] = useState({
        title: '',
        description: ''
    })


    const { t, i18n } = useTranslation();



    useEffect(() => {
        axios.get(`${API_URL}/get-cards`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${auth.getAccessToken()}`
            }
        }).then((response) => {
            setCardsArray(response.data)
            setSelectedImage((response.data).length + 1)
        }).catch(err => {
            console.log(err);
        })
    }, [message])



    const auth = useAuth();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile) return setMessage({ type: 'error', message: 'error_select_image' });



        const MAX_SIZE_MB = 2;
        const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

        // Validar tamaño de la imagen
        if (selectedFile.size > MAX_SIZE_BYTES) {
            return setMessage({ type: 'error', message: `files_too_large` });
        }

        let formData = new FormData();
        if(selectedFile !== 'same'){
        formData.append('title', cardsInputs.title);
        formData.append('description', cardsInputs.description);
        formData.append('image', selectedFile);
        }else {
            formData = {
                title: cardsInputs.title,
                description: cardsInputs.description,
                image: 'same'
            }
        }

        try {
            await axios.post(`${API_URL}/upload?cardImg=${selectedImage}`, formData, {
                headers: {
                    'Content-Type': selectedFile !== 'same' ? 'multipart/form-data' : 'application/json',
                    'Authorization': `Bearer ${auth.getAccessToken()}`
                }
            });

            const response = await axios.get(`${API_URL}/get-cards`, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            setCardsArray(response.data)
            setMessage({ type: 'success', message: 'file_upload_success' });
            setCardsInputs({
                title: '',
                description: ''
            })
            setSelectedFile(null)            
            setCardsState('menu');
        
        } catch (error) {
            console.error('Error al subir el archivo:', error);
            setMessage({ type: 'error', message: 'file_upload_error' });
        }
    };



    async function handleDelete(e, id) {
        e.preventDefault()

        try {
            const response = await axios.post(`${API_URL}/delete-card`, JSON.stringify({ id }), {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${auth.getAccessToken()}`
                }
            });

            let filteredArray = cardsArray.filter((e, i) => i !== id);

            setCardsArray(filteredArray);
            setSelectedImage(selectedImage - 1);
            setMessage({ type: 'success', message: response.data })
        } catch (error) {
            console.error('Error al borrar card:', error);
        }
    }



    function handleModify(event, data, pos) {
        event.preventDefault();

        setCardsState('load');
        setCardsInputs({
            title: data.title,
            description: data.description
        })
        setSelectedFile('same');
        setSelectedImage(pos+1);
    }




    return (


        <div className="account-row-2">
            <div className="section-title">
                <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
                <h2>{t('translations:page_settings')}</h2>
                <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
            </div>

            <span className="line">
            </span>

            {cardsState === 'load' && (
                <form className="account-row__user-info" onSubmit={handleSubmit}>
                    <input type="hidden" name="_token" value="iKf7WpIL2qicOGt2sZWSKFoSMPLseElKWvjQ7zXK" autoComplete="off" />
                    <div className="form-container__item-container" data-characters="30">
                        <label className="form-container__item-title" htmlFor="F110KEZS6D">
                            {t('translations:title')}
                        </label>

                        <input
                            type="text"
                            className="form-container__item-input"
                            name="title"
                            onChange={(e) => setCardsInputs({ ...cardsInputs, [e.target.name]: e.target.value })}
                            value={cardsInputs.title}
                            id="F110KEZS6D"
                        />

                        <label style={{ marginTop: '20px' }} className="form-container__item-title" htmlFor="F110KEZS6D">
                            {t('translations:description')}
                        </label>

                        <input
                            type="text"
                            className="form-container__item-input"
                            name="description"
                            onChange={(e) => setCardsInputs({ ...cardsInputs, [e.target.name]: e.target.value })}
                            value={cardsInputs.description}
                        />

                        <div className="form-container__item-bag">
                            <p className="form-container__item-error">
                            </p>
                            <span className="form-container__item-counter">
                                30
                            </span>
                        </div>
                    </div>

                    <div className="form-container__image-preview-container mt-2">
                        <input
                            onChange={(e) => handleFileChange(e)}
                            type="file"
                            accept="image/*"
                            className="displayNone"
                            id="upload_file_btn"
                            name="image"
                        />
                        <label htmlFor="upload_file_btn" className="form-container__image-label-container">
                            <div className="form-container__image-preview-empty-image" id="upload_file_empty">
                                <img src={require("../../img/icon/image-preview.png")} className="form-container__image-preview-icon" alt={t('translations:click_to_upload')} />
                                <p>
                                    {t('translations:click_to_upload')}
                                </p>
                            </div>
                            <div className="form-container__image-preview-image-uploaded displayNone" id="upload_file_uploaded">
                                <img src="" className="form-container__image-preview-image" id="upload_file_img" alt={t('translations:uploaded_image')} />
                                <span className="form-container__image-preview-change">
                                    {t('translations:click_to_change')}
                                </span>
                                <button type="button" id="upload_file_delete" className="card-container__action-button">
                                    {t('translations:delete')}
                                </button>
                            </div>
                        </label>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <button style={{ margin: '10px 5px' }} type="submit" className="noDefaultStyle upload-image__btn-submit">
                            {t('translations:save')}
                        </button>
                        <button onClick={() => { setCardsState('menu') }} style={{ margin: '10px 5px' }} type="button" className="noDefaultStyle account-form__button">
                            {t('translations:back')}
                        </button>
                    </div>
                </form>
            )}

            {cardsState === 'menu' && cardsArray.length > 0 && (
                <div className="account-row__user-info">
                    <div className="card-container">
                        {cardsArray.map((e, i) => (
                            <div key={i + 'cards'} className="card-container__card">
                                <img src={`${API_URL + e.imgURL}`} className="card-container__image" alt={t('translations:card_image')} />

                                <form className="card-container__action-container">
                                    <input type="hidden" name="_method" value="delete" />
                                    <input type="hidden" name="_token" value="jY9P22z80X0JVDgxT7lZsTaiQDiZLPWzFIYktf8j" autoComplete="off" />
                                    <button onClick={(e) => handleDelete(e, i)} className="noDefaultStyle card-container__action-button">
                                        {t('translations:delete')}
                                    </button>
                                    <button onClick={(event) => handleModify(event, e, i)} className="noDefaultStyle card-container__action-button">
                                        {t('translations:modify')}
                                    </button>
                                </form>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {cardsState === 'menu' && cardsArray.length > 0 && (
                <span className="line">
                </span>
            )}

            <div className="account-row__user-manage">
                {cardsState === 'menu' && (

                    <div className="parent-div" style={{ width: '100%', position: 'relative' }}>
                        <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex: '500' }} className="noDefaultStyle account-row__manage-row">
                        </div>
                        <div onClick={() => setCardsState('load')} style={{ cursor: 'pointer', position: 'relative', zIndex: '1000' }} className="noDefaultStyle account-row__manage-row2">
                            <h3 style={{ fontSize: '16px', position: 'relative' }}>
                                {t('translations:create')}
                            </h3>
                            <span>
                                {t('translations:main_cards')}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            {cardsState === 'menu' && (
                <button onClick={() => { setSelectedOption('pagesettings') }} style={{ margin: '10px 5px' }} type="button" className="noDefaultStyle account-form__button">
                    {t('translations:back')}
                </button>
            )}
        </div>
    )
}