import React, { useEffect, useState } from "react";
import axios from 'axios';
import { API_URL } from "../../Auth/authConstants2";
import { useAuth } from "../../Auth/AuthProvider";
import { useTranslation } from "react-i18next";
import AlertModal from "../Addons/AlertModal";




export default function SliderSettings({ setSelectedOption, message, setMessage }) {


  const auth = useAuth();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState('image');
  const { t, i18n } = useTranslation();

  /*
    useEffect(()=> {    
      const lng = localStorage.getItem('language') || navigator.language;
      i18n.changeLanguage(lng);
    },[])
  */


  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) return setMessage({ type: 'error', message: 'error_select_image' });
    if (!fileType) return setMessage({ type: 'error', message: 'error_select_file_type' });
    if (fileType !== 'mp4' && fileType !== 'image') return setMessage({ type: 'error', message: 'error_select_file_type' });


    const MAX_SIZE_MB = 2;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
    // Validar tamaño de la imagen
    if (selectedFile.size > MAX_SIZE_BYTES && fileType !== 'mp4') {
      return setMessage({ type: 'error', message: `files_too_large` });
    }

    const formData = new FormData();
    formData.append('type', fileType);
    formData.append('image', selectedFile);


    try {
      await axios.post(`${API_URL}/upload?sliderImg=${selectedImage}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${auth.getAccessToken()}`
        }
      });
      setMessage({ type: 'success', message: 'file_upload_success' });
      setSelectedFile(null)
    } catch (error) {
      console.error('Error al subir el archivo:', error);
      setMessage({ type: 'error', message: 'file_upload_error' });
    }
  }

  const [sliderState, setSliderState] = useState('menu');
  const [selectedImage, setSelectedImage] = useState(0);


  return (

    <div className="account-row-2">
      <div className="section-title">
        <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
        <h2>{t('translations:page_settings')}</h2>
        <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
      </div>

      <span className="line">
      </span>

      {sliderState === 'load' && (
        <form className="account-row__user-info" onSubmit={handleSubmit}>
          <input type="hidden" name="_token" value="iKf7WpIL2qicOGt2sZWSKFoSMPLseElKWvjQ7zXK" autoComplete="off" />
          <div className="form-container__image-preview-container">
            <input onChange={(e) => handleFileChange(e)} type="file" accept={fileType == 'image' ? "image/*" : "video/mp4"} className="displayNone" id="upload_file_btn" name="image" />
            <label htmlFor="upload_file_btn" className="form-container__image-label-container">
              <div className="form-container__image-preview-empty-image" id="upload_file_empty">
                <img src={require("../../img/icon/image-preview.png")} className="form-container__image-preview-icon" alt={t('translations:upload_image_alt')} />
                <p>
                  {t('translations:click_to_upload')}
                </p>
              </div>
              <div className="form-container__image-preview-image-uploaded displayNone" id="upload_file_uploaded">
                <img src="" className="form-container__image-preview-image" id="upload_file_img" alt={t('translations:uploaded_image_alt')} />
                <span className="form-container__image-preview-change">
                  {t('translations:click_to_change')}
                </span>
                <button type="button" id="upload_file_delete" className="card-container__action-button">
                  {t('translations:delete')}
                </button>
              </div>
            </label>
            <select onChange={(e) => {setFileType(e.target.value)}} style={{marginTop:'15px', background: '#160202',color: '#783939', border: '0.063rem solid #783939', padding: '5px 20px 5px 5px', fontSize: '16px'}}>
              <option disabled>{t('translations:file_type')}</option>
              <option selected value={'image'}>{t('translations:image')}</option>
              <option value={'mp4'}>mp4</option>
            </select>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
            <button style={{ margin: '10px 5px' }} type="submit" className="noDefaultStyle upload-image__btn-submit">
              {t('translations:save')}
            </button>
            <button onClick={() => { setSliderState('menu') }} style={{ margin: '10px 5px' }} type="button" className="noDefaultStyle account-form__button">
              {t('translations:back')}
            </button>
          </div>
        </form>
      )}

      <div className="account-row__user-manage">
        {sliderState === 'menu' && [1, 2, 3, 4, 5].map(e => (

          <div key={e + 'slider'} className="parent-div" style={{ width: '100%', position: 'relative' }}>
            <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex: '500' }} className="noDefaultStyle account-row__manage-row">
            </div>
            <div
              onClick={() => {
                setSelectedImage(e);
                setSliderState('load');
              }} style={{ cursor: 'pointer', position: 'relative', zIndex: '1000' }} className="noDefaultStyle account-row__manage-row2">
              <h3 style={{ fontSize: '16px', position: 'relative' }}>
                {t('translations:slider')}
              </h3>
              <span>
                {t('translations:change_slider_image', { number: e })}
              </span>
            </div>
          </div>
        ))}
      </div>
      {sliderState === 'menu' && (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
          <button onClick={() => { setSelectedOption('pagesettings') }} style={{ margin: '10px 0px' }} type="button" className="noDefaultStyle account-form__button">
            {t('translations:back')}
          </button>
        </div>
      )}
    </div>
  )
}