import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Auth/AuthProvider";
import { API_URL } from "../Auth/authConstants2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";


export default function Navbar({ setIsModal, navbar, setCurrentCategory = false, setCurrentPage = false }) {


    const auth = useAuth();
    const { t, i18n } = useTranslation();


    const [clicked, setClicked] = useState({ active: false, type: '' })


    async function handleSignOut(e) {
        e.preventDefault();
        auth.checkTokens();
        try {
            const response = await fetch(`${API_URL}/logout`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.getRefreshToken()}`,
                },
            });
            if (response.ok) {
                auth.signout();
            }
        } catch (error) {
            console.log(error);
        }
    }


    let lang = [
        {
            "name": "Español",
            "img": "Espana.gif",
            "iso": "es"
        },
        {
            "name": "English",
            "img": "EstadosUnidos.gif",
            "iso": "en"
        },
        {
            "name": "Português",
            "img": "Portugal.gif",
            "iso": "pt"
        },
        {
            "name": "Bulgarian",
            "img": "Bulgaria.gif",
            "iso": "bg"
        },
        {
            "name": "Français",
            "img": "France.gif",
            "iso": "fr"
        },
        {
            "name": "Malagasy",
            "img": "Madagascar.gif",
            "iso": "mg"
        },
        {
            "name": "Czech",
            "img": "RepublicaCheca.gif",
            "iso": "cs"
        },
        {
            "name": "Afrikaans",
            "img": "Sudafrica.gif",
            "iso": "af"
        }
    ]


    const [isMenu, setIsMenu] = useState(false);

    return (

        window.innerWidth > 500 ? <nav aria-label={t('translations:menu_label')} className={navbar ? "unselectable main-menu main-menu--scroll" : "unselectable main-menu"} id="main-menu">
            <div className="main-menu__item-aligner">
                <ul className="noDefaultStyle main-menu__items">
                    <li className="main-menu__item">
                        <Link to="/" className="main-menu__item-title">
                            {t('translations:home')}
                        </Link>
                    </li>

                    <li className="main-menu__item">
                        <Link to="/news" className="main-menu__item-title">
                            {t('translations:news')}
                        </Link>
                    </li>
                    <li className="main-menu__item">
                        <Link to="/download" className="main-menu__item-title">
                            {t('translations:download')}
                        </Link>

                    </li>
                    {/*<li onClick={() => setClicked({ active: !clicked.active, type: 'item-shop' })} className={clicked.active && clicked.type === 'item-shop' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span to="http://51.222.106.68:8080/" className="main-menu__item-title">
                            {t('translations:item_shop')}
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        {clicked.type == 'item-shop' && clicked.active && <div className="main-menu__selection-subitems">
                            <iframe
                                id="inlineFrameExample"
                                title="Inline Frame Example"
                                width="1018"
                                height="656"
                                src="http://51.222.106.68:8080/">
                            </iframe>
                        </div>}
                    </li>*/}

                    <li onClick={() => setClicked({ active: !clicked.active, type: 'ranking' })} className={clicked.active && clicked.type === 'ranking' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span className="main-menu__item-title">
                            {t('translations:ranking')}
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        <div className="main-menu__selection-subitems">
                            <Link state={"Players"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Players");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/players" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_players')}
                            </Link>
                            <Link state={"Guilds"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Guilds");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/guilds" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_guilds')}
                            </Link>
                            <Link state={"Votes"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Votes");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/votes" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_votes')}
                            </Link>
                            <Link state={"Online"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Online");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/online" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_online')}
                            </Link>
                        </div>
                    </li>

                    <li onClick={() => setClicked({ active: !clicked.active, type: 'language' })} className={clicked.active && clicked.type === 'language' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span className="main-menu__item-title">
                            <div className="main-menu__flag-container">
                                <img src={require(`../img/flags/${t('translations:flag_img')}.gif`)} className="main-menu__flag-selection" alt={t('translations:language_flag_alt')} />
                                <span>
                                    {t('translations:language')}
                                </span>
                            </div>
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        <div style={{ maxHeight: '120px', overflow: 'scroll', overflowX: 'hidden', display: 'block' }} className="main-menu__selection-subitems">
                            {
                                lang.map((e, i) => {
                                    return (
                                        <div key={i + 'lang'} style={{ marginTop: i > 0 ? '5px' : '0px' }} onClick={() => {
                                            i18n.changeLanguage(e.iso)
                                            localStorage.setItem("language", e.iso)
                                        }} className="noDefaultStyle btn-session-menu-selection">
                                            <div className="main-menu__flag-container">
                                                <img src={require(`../img/flags/${e.img}`)} className="main-menu__flag-selection" alt={t('translations:spanish_flag_alt')} />
                                                <span>
                                                    {e.name}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </li>
                </ul>

                {auth.isAuthenticated && <div className="main-menu__extra-items-container">
                    <li onClick={() => setClicked({ active: !clicked.active, type: 'user' })} style={{ cursor: 'pointer' }} className={clicked.active && clicked.type === 'user' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span className="main-menu__item-title">
                            {auth.getUser()?.username}
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        <div className="main-menu__selection-subitems">
                            <Link to="/profile" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:dashboard')}
                            </Link>
                            <form className="noDefaultStyle btn-session-menu-selection">
                                <input type="hidden" name="_token" value="vRQmRjIjXj0wvLPXnineb8prWOBl1S9Pth2Xu96k" autoComplete="off" />
                                <button onClick={(e) => handleSignOut(e)} type="submit" className="noDefaultStyle">
                                    {t('translations:log_out')}
                                </button>
                            </form>
                        </div>
                    </li>
                </div>}

                {!auth.isAuthenticated && <div className="main-menu__extra-items-container">
                    <li onClick={() => setIsModal({ active: true, type: 'create' })} className="main-menu__item" id="btn-session-register">
                        <p className="main-menu__item-title">
                            {t('translations:create_account')}
                        </p>
                    </li>
                    <li onClick={() => setIsModal({ active: true, type: 'login' })} className="main-menu__login-button" id="btn-session-login">
                        {t('translations:login')}
                    </li>
                </div>}
            </div>

            <button className="noDefaultStyle main-menu__switch" id="main-menu__switch">
                <span className="main-menu__switch-line">
                </span>
            </button>
        </nav> :
            <nav style={{ minHeight: '3.75rem', position: 'fixed', top: '0', width: '100%', zIndex: '1000', display:'block' }} className={navbar || isMenu ? "unselectable main-menu main-menu--scroll" : "unselectable main-menu"}>
                <div style={{ width: '100%', position: 'relative'}}>
                    
                <FontAwesomeIcon onClick={() => setIsMenu(!isMenu)} style={{ fontSize: '25px', float: 'right', top: '7px', left: '15px', color: '#f2c995', position:'absolute' }} icon={faBars} />
                {auth.isAuthenticated && <div style={{marginTop: '7px', marginLeft: 'auto', marginRight:'10px'}} className="main-menu__extra-items-container">
                    <li onClick={() => setClicked({ active: !clicked.active, type: 'user' })} style={{ cursor: 'pointer' }} className={clicked.active && clicked.type === 'user' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span className="main-menu__item-title">
                            {auth.getUser()?.username}
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        <div className="main-menu__selection-subitems">
                            <Link to="/profile" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:dashboard')}
                            </Link>
                            <form className="noDefaultStyle btn-session-menu-selection">
                                <input type="hidden" name="_token" value="vRQmRjIjXj0wvLPXnineb8prWOBl1S9Pth2Xu96k" autoComplete="off" />
                                <button onClick={(e) => handleSignOut(e)} type="submit" className="noDefaultStyle">
                                    {t('translations:log_out')}
                                </button>
                            </form>
                        </div>
                    </li>
                </div>}

                {!auth.isAuthenticated && <div style={{marginTop: '7px', marginLeft: 'auto', marginRight:'10px'}} className="main-menu__extra-items-container">
                    <li onClick={() => setIsModal({ active: true, type: 'create' })} className="main-menu__item" id="btn-session-register">
                        <p className="main-menu__item-title">
                            {t('translations:create_account')}
                        </p>
                    </li>
                    <li onClick={() => setIsModal({ active: true, type: 'login' })} className="main-menu__login-button" id="btn-session-login">
                        {t('translations:login')}
                    </li>
                </div>}
                    
                </div>
                { <ul className="" style={{ display: !isMenu ? 'none' : 'block', marginTop: '3.5rem', paddingBottom: '10px' }}>
                    <li  style={{paddingBottom: '10px'}} className="main-menu__item">
                        <Link style={{fontSize: '20px'}} to="/" className="main-menu__item-title">
                            {t('translations:home')}
                        </Link>
                    </li>

                    <li  style={{paddingBottom: '10px'}} className="main-menu__item">
                        <Link style={{fontSize: '20px'}} to="/news" className="main-menu__item-title">
                            {t('translations:news')}
                        </Link>
                    </li>
                    <li style={{paddingBottom: '10px'}} className="main-menu__item">
                        <Link style={{fontSize: '20px'}} to="/download" className="main-menu__item-title">
                            {t('translations:download')}
                        </Link>

                    </li>
                    {/*<li onClick={() => setClicked({ active: !clicked.active, type: 'item-shop' })} className={clicked.active && clicked.type === 'item-shop' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span to="http://51.222.106.68:8080/" className="main-menu__item-title">
                            {t('translations:item_shop')}
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        {clicked.type == 'item-shop' && clicked.active && <div className="main-menu__selection-subitems">
                            <iframe
                                id="inlineFrameExample"
                                title="Inline Frame Example"
                                width="1018"
                                height="656"
                                src="http://51.222.106.68:8080/">
                            </iframe>
                        </div>}
                    </li>*/}

                    <li style={{justifyContent:'center', paddingBottom: '10px'}} onClick={() => setClicked({ active: !clicked.active, type: 'ranking' })} className={clicked.active && clicked.type === 'ranking' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span style={{fontSize: '20px'}} className="main-menu__item-title">
                            {t('translations:ranking')}
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        <div style={{width: '100%'}} className="main-menu__selection-subitems">
                            <Link state={"Players"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Players");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/players" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_players')}
                            </Link>
                            <Link state={"Guilds"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Guilds");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/guilds" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_guilds')}
                            </Link>
                            <Link state={"Votes"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Votes");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/votes" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_votes')}
                            </Link>
                            <Link state={"Online"} onClick={() => {
                                setCurrentCategory && setCurrentCategory("Online");
                                setCurrentPage && setCurrentPage(1);
                            }} to="/rank/online" className="noDefaultStyle btn-session-menu-selection">
                                {t('translations:top_online')}
                            </Link>
                        </div>
                    </li>

                    <li style={{justifyContent:'center', paddingBottom: '10px'}} onClick={() => setClicked({ active: !clicked.active, type: 'language' })} className={clicked.active && clicked.type === 'language' ? "main-menu__selection-item main-menu__selection-item--active" : "main-menu__selection-item"}>
                        <span style={{fontSize: '20px'}} className="main-menu__item-title">
                            <div className="main-menu__flag-container">
                                <img src={require(`../img/flags/${t('translations:flag_img')}.gif`)} className="main-menu__flag-selection" alt={t('translations:language_flag_alt')} />
                                <span>
                                    {t('translations:language')}
                                </span>
                            </div>
                        </span>
                        <span className="main-menu__selection-item-arrow">
                        </span>
                        <div style={{  overflow: 'scroll', overflowX: 'hidden', display: 'block', width: '100%', justifyContent:'center' }} className="main-menu__selection-subitems">
                            {
                                lang.map((e, i) => {
                                    return (
                                        <div key={i + 'lang'} style={{margin:'auto', marginTop: i > 0 ? '5px' : '0px', width:'100%'  }} onClick={() => {
                                            i18n.changeLanguage(e.iso)
                                            localStorage.setItem("language", e.iso)
                                        }} className="noDefaultStyle btn-session-menu-selection">
                                            <div className="main-menu__flag-container">
                                                <img src={require(`../img/flags/${e.img}`)} className="main-menu__flag-selection" alt={t('translations:spanish_flag_alt')} />
                                                <span>
                                                    {e.name}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </li>
                </ul>}
            </nav>
    )
}