import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Modal from "./Addons/Modal";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Auth/AuthProvider";
import axios from "axios";
import { API_URL } from "../Auth/authConstants2";
import Loading from "./Addons/Loading";
import DiscordButton from "./Addons/DiscordButton";


export default function Download() {


    const { t } = useTranslation();
    const auth = useAuth();

    const [ downloadsLink, setDownloadsLink ] = useState([]);

    useEffect(() => {
        auth.checkTokens();
        axios.get(`${API_URL}/get-downloads`).then((result)=> {
            setDownloadsLink(result.data);
        })
    }, [])


    const [isModal, setIsModal] = useState({
        active: false,
        type: ''
    });
    

    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (isModal.active || loading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModal, loading])


    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

    }



    window.addEventListener("scroll", changeNavbar);



    return (
        <div className="body__content" id="body__content">
            <Navbar setIsModal={setIsModal} navbar={navbar} />

            <section className="download-section">

                <div className="section-title">
                    <img src={require("../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                    <h2>
                        {t('translations:client')}
                    </h2>
                    <img src={require("../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_prop_alt')} />
                </div>

                <section className="download-section__card-container">
                    {
                        downloadsLink?.map((e,i) => {
                            return ( <a href={`${e.description}`} key={i+'download'} className="noDefaultStyle download-section__card">
                                <div className="download-section__card-image">
                                    <div>
                                    <img src={`${API_URL}${e.imgURL}`} className="download-section__card-icon" alt={e.imgAlt} />
                                    </div>
                                </div>
                                <div className="download-section__card-description">
                                    <h2>
                                        {t('translations:download_mirror')}
                                    </h2>
                                    <p>
                                        {t('translations:client_files')} (2.4GB)
                                    </p>
                                    <span>
                                        {t('translations:date')}
                                    </span>
                                </div>
                            </a>)

                        })
                    }
                </section>

                <span className="line">
                </span>

                <div className="download-client-container__download-tables-container">
                    <div className="download-client-container__table-information">
                        <div className="download-client-container__information-title">
                            {t('translations:min_system')}
                        </div>

                        <div className="download-client-container__information-rows">

                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:os')}
                                </p>
                                <span>
                                    {t('translations:xp_vista_7_8_10')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:cpu')}
                                </p>
                                <span>
                                    {t('translations:pentium_4_1ghz')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:memory_ram')}
                                </p>
                                <span>
                                    {t('translations:1gb')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:disk')}
                                </p>
                                <span>
                                    {t('translations:6gb')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:graphics')}
                                </p>
                                <span>
                                    {t('translations:graphics_min_32mb')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:sound')}
                                </p>
                                <span>
                                    {t('translations:support_directx_9')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:mouse')}
                                </p>
                                <span>
                                    {t('translations:windows_mouse_compatibility')}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="download-client-container__table-information">
                        <div className="download-client-container__information-title">
                            {t('translations:rec_system')}
                        </div>

                        <div className="download-client-container__information-rows">

                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:os')}
                                </p>
                                <span>
                                    {t('translations:windows_7_8_10')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:cpu')}
                                </p>
                                <span>
                                    {t('translations:intel_i3')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:memory_ram')}
                                </p>
                                <span>
                                    {t('translations:2gb')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:disk')}
                                </p>
                                <span>
                                    {t('translations:7gb')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:graphics')}
                                </p>
                                <span>
                                    {t('translations:graphics_min_64mb')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:sound')}
                                </p>
                                <span>
                                    {t('translations:support_directx_9')}
                                </span>
                            </div>
                            <div className="download-client-container__information-row">
                                <p>
                                    {t('translations:mouse')}
                                </p>
                                <span>
                                    {t('translations:windows_mouse_compatibility')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {loading && <Loading request={true} />}
            <DiscordButton />

            <Footer />

            <Modal isModal={isModal} setIsModal={setIsModal} setLoading={setLoading} />
        </div>


    )
}