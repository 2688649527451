import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Modal from "./Addons/Modal";
import axios from 'axios'
import { API_URL } from "../Auth/authConstants2";
import { useTranslation } from "react-i18next";


export default function Reset({ handleModal, setIsModal }) {

    const { t } = useTranslation();




    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

    }

    const [inputEmail, setInputEmail] = useState({
        email: ""
    })


    const [errorResponse, setErrorResponse] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const lng = localStorage.getItem('language');
            const result = await axios.post(`${API_URL}/request-reset-password`, { email: inputEmail.email, lang: lng });
            setErrorResponse(result.data)
        } catch (err) {
            setErrorResponse(err);
        }
    }



    window.addEventListener("scroll", changeNavbar);


    function handleClose() {
        setErrorResponse(false);
        handleModal()
    }


    return (


        <form className="user-session__form-container user-session__width" style={{ display: 'flex', position: 'relative', backgroundImage: 'url("../../img/background/table2.png") !important' }} id="session-form-login">
            
            <div style={{ position: 'absolute', right: '10px', top: '8px', zIndex:'1000' }} onClick={() => handleClose()} className="noDefaultStyle modal-container__close-button">
            </div>
            <input type="hidden" name="_token" autoComplete="off" />
            <div className="section-title user-session__width">

                <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%', float: 'left' }} src={require("../img/props/title.png")} className="news-container__prop" />
                <div style={{ display: 'flex' }}><span style={{ marginTop: '2px' }} className="noDefaultStyle news-container__control-arrow"></span>{t('translations:recover_link')}</div>

                <img style={{ filter: 'sepia(1) saturate(10) hue-rotate(-50deg)', width: '100%' }} src={require("../img/props/title2.png")} className="section-title__prop" />
            </div>


            <div className="user-session__form-row">
                <div className="user-session__form-input-container">
                    <img src={require("../img/icon/email.png")} className="reset-form-container__form-icon" />
                    <input style={{ fontSize: '14px' }} type="email" name="email" value={inputEmail.email} onChange={(e) => { setInputEmail({ [e.target.name]: e.target.value }) }} className="noDefaultStyle reset-form-container__form-input" placeholder="Email" />
                </div>
            </div>


            {errorResponse && <div style={{ padding: '10px 20px', textAlign: 'center', color: errorResponse?.includes('success') ? "green" : "red" }} className="error-message">{t(`translations:${errorResponse}`)}</div>}


            <button onClick={(e) => handleSubmit(e)} style={{ background: 'none', marginTop: '30px', marginBottom: '30px' }} type="submit" className="noDefaultStyle user-session__button">

                <img style={{ filter: 'sepia(1) saturate(6) hue-rotate(-50deg) brightness(0.8) contrast(1.3)', position: 'absolute' }} src={require("../img/props/bttn-copy.png")}></img>
                <span style={{ zIndex: '100' }}>{t('translations:get_link')}</span>
            </button>


        </form>




    )
}