import React, { useEffect, useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { API_URL } from "../../Auth/authConstants2";
import { useTranslation } from "react-i18next";


export default function ChangePass({ setSelectedOption, message, setMessage, setLoading }) {

    const auth = useAuth();

    const [userData, setUserData] = useState({
        currentPassword: '',
        password: '',
        repeatPassword: ''
    })


    
    const { t } = useTranslation();


    const [errors, setErrors] = useState({
        currentPassword: '',
        password: '',
        repeatPassword: ''
    });

    const [updateStates, setUpdateStates] = useState(false);

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&/()=@]).{8,16}$/;

  const validateForm = () => {
    const newErrors = {};

    if (!userData.currentPassword) {
        newErrors.currentPassword = t('translations:current_password_required');
      }
    
      if (!userData.password) {
        newErrors.password = t('translations:new_password_required');
      } else if (!passwordPattern.test(userData.password)) {
        newErrors.password = t('translations:new_password_pattern');
      }
    
      if (userData.password !== userData.repeatPassword) {
        newErrors.repeatPassword = t('translations:passwords_do_not_match');
      }
    
    Object.keys(newErrors).length > 0 ? setErrors(newErrors) : setErrors({});

    setUpdateStates(!updateStates);

    return Object.keys(newErrors).length === 0;
  };

    async function handleChangePass(e) {
        e.preventDefault();
        let validate = validateForm();

        if (validate) {
            setLoading(true);


            try {
                const response = await fetch(`${API_URL}/changepass`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.getAccessToken()}`,
                    },
                    body: JSON.stringify({
                        id: auth.getUser()?.id,
                        currentPassword: userData.currentPassword,
                        password: userData.password
                    })
                });
                if (response.ok) {
                    setUserData({
                        currentPassword: '',
                        password: '',
                        repeatPassword: ''
                    });
                    setMessage({type: 'success', message:'password_changed'})
                }else {
                    setMessage({type: 'error', message:(await response.json()).error})
                }
            } catch (error) {
                console.log(error);
            }
        }
        setLoading(false);
    }


    return (


        <div className="account-row-2">
            <div className="section-title">
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_alt')} />
                <h2>
                    {t('translations:my_password')}
                </h2>
                <img src={require("../../img/props/section-title.png")} className="section-title__prop" alt={t('translations:section_title_alt')} />
            </div>

            <div className="account-row__user-info">
                <form className="account-form">
                    <input type="hidden" name="_method" value="PUT" />
                    <input type="hidden" name="_token" value="HcCRTrzJXWEqwdppC05kRvE9mOyT3Ta0qpJo7BSP" autoComplete="off" />
                    <div className="account-form__block">
                        <label className="account-form__title">
                            {t('translations:current_password')}
                        </label>
                        <input 
                            type="password" 
                            name="currentPassword" 
                            value={userData.currentPassword} 
                            onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })} 
                            className="noDefaultStyle account-form__input" 
                            placeholder={t('translations:current_password_placeholder')} 
                        />
                    </div>
                    <div className="account-form__block">
                        <label className="account-form__title">
                            {t('translations:new_password')}
                        </label>
                        <input 
                            type="password" 
                            name="password" 
                            value={userData.password} 
                            onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })} 
                            className="noDefaultStyle account-form__input" 
                            placeholder={t('translations:new_password_placeholder')} 
                        />
                    </div>
                    <div className="account-form__block">
                        <label className="account-form__title">
                            {t('translations:new_password_confirm')}
                        </label>
                        <input 
                            type="password" 
                            name="repeatPassword" 
                            value={userData.repeatPassword} 
                            onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })} 
                            className="noDefaultStyle account-form__input" 
                            placeholder={t('translations:new_password_confirm_placeholder')} 
                        />
                    </div>
                    <div className="account-form__block">
                        <label style={{ color: '#d8869c' }} className="account-form__title">
                            {errors?.currentPassword}
                        </label>            
                        <label style={{ color: '#d8869c' }} className="account-form__title">
                            {errors?.password}
                        </label>                        
                        <label style={{ color: '#d8869c' }} className="account-form__title">
                            {errors?.repeatPassword}
                        </label>                                    
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button 
                            onClick={(e) => handleChangePass(e)} 
                            style={{ margin: '10px 5px' }} 
                            type="submit" 
                            className="noDefaultStyle account-form__button"
                        >
                            {t('translations:confirm')}
                        </button>
                        <button 
                            onClick={() => { setSelectedOption('dashboard') }} 
                            style={{ margin: '10px 5px' }} 
                            type="button" 
                            className="noDefaultStyle account-form__button"
                        >
                            {t('translations:back')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}