import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { API_URL } from "../../Auth/authConstants2";
import { useAuth } from "../../Auth/AuthProvider";



export default function Vote() {

  const { t } = useTranslation();
  const [reward, setReward] = useState(0);
  const [voteData, setVoteData] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    axios.get(`${API_URL}/get-coins?id=${auth.getUser().id}`).then((response) => {
      setReward(response.data.userCoins?.coins_earned);
      setVoteData(response.data.vote)
    })
  }, [])


  async function handleCheck() {
    axios.post(`${API_URL}/vote`, { type: 1, username: auth.getUser().username })
    axios.get(`${API_URL}/get-coins?id=${auth.getUser().id}`).then((response) => {
      setReward(response.data.userCoins?.coins_earned);
    })
  }



  return (
    <div className="account-row-2">
      <div className="section-title">
        <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
        <h2>{t('vote')}</h2>
        <img src={require(`../../img/props/section-title.png`)} className="section-title__prop" alt={t('alt.section_title')} />
      </div>

      <div className="account-row__user-info">
        <div className="account-row__user-row">
          <div className="account-row__user-title">
            {t('coins_earned')}:
          </div>
          <div className="account-row__user-description">
            {reward || 0}
          </div>
        </div>
      </div>


      <span className="line"></span>

      
      <div onClick={() => handleCheck()} className="parent-div" style={{ width: '90%', position: 'relative', marginTop: '15px', marginBottom: '15px' }}>
        <div style={{ cursor: 'pointer', width: '100%', position: 'absolute', zIndex: '500' }} className="noDefaultStyle account-row__manage-row">
        </div>        
        <div style={{ cursor: 'pointer', position: 'relative', zIndex: '1000' }} className="noDefaultStyle account-row__manage-row2">
          <h3 style={{ fontSize: '16px', position: 'relative' }}>
            {t('translations:notice')}
          </h3>
          <span>
            {t('translations:votes_notice')}
          </span>
        </div>
      </div>

      <div className="account-row__user-info">
        <table className="table-vote-container__table table-vote-container__table--hover">
          <thead>
            <tr>
              <th>{t('translations:name')}</th>
              <th>{t('translations:reward')}</th>
              <th>{t('translations:action')}</th>
            </tr>
          </thead>
          <tbody>{voteData?.length && voteData?.map((e, i) => {
                return (
                  <tr key={i + 'votes'}>
                    <td>{e.site_name}</td>
                    <td>{e?.coins_earned}</td>
                    <td>{e.is_enabled ? <a style={{ fontWeight: 'bold' }} href={i == 0 || i == 1 ? `${e.site + auth.getUser().username}` : (i == 2 ? `${e.site + auth.getUser().id}` : `${e.site}`)}>Vote</a> : <div >Vote</div>}</td>
                  </tr>
                )
              })}</tbody>
        </table>
      </div>
    </div>
  )
}